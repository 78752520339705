import store from '../store'
import StoreConstants from '../StoreConstants'
import api from './api'
import Auth from '@aws-amplify/auth'

import * as AWS from 'aws-sdk/global'

export default {

  async initAwsBaseAwait(domain, idToken, expiresAt) {
    console.log('initAwsBaseAwait')
    this.idToken = idToken

    AWS.config.correctClockSkew = true
    AWS.config.region = 'us-west-2'
    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
      region: 'us-west-2',
      IdentityPoolId: process.env.VUE_APP_COGNITO_IDENTITY_POOL_ID,
      RoleArn: process.env.VUE_APP_COGNITO_ROLE,
      Logins: {
        [domain]: idToken
      },
    })


    let error
    await AWS.config.credentials.getPromise().catch((err) => {
      error = err
      // eslint-disable-next-line no-console
      console.log(error)
    })

    if(error) throw error

    //used for the new, modular apis
    store.commit(StoreConstants.commits.setCredentials, {
      accessKey: AWS.config.credentials.accessKeyId,
      secretKey: AWS.config.credentials.secretAccessKey,
      sessionToken: AWS.config.credentials.sessionToken,
      region: 'us-west-2',
      defaultContentType: 'application/json',
      defaultAcceptType: 'application/json'
    })

    //used for the old monolith api
    api.setCredentials(this, AWS.config.credentials.accessKeyId,
      AWS.config.credentials.secretAccessKey,
      AWS.config.credentials.sessionToken,
      expiresAt
    )
    console.log('initAwsBaseAwait done')
  },
  async appLogin(accountId, deviceUUID, domain, idToken, expiresAt) {
    store.commit(StoreConstants.commits.setLoggedInEmail, accountId)
    store.commit(StoreConstants.commits.setDeviceUUID, deviceUUID)
    await this.initAwsBaseAwait(domain, idToken, expiresAt)
  },
  async setCurrentSession(session) {

    let expiresAt = session.idToken.payload.exp * 1000
    this.expires_at = expiresAt

    console.log(`expires at ${new Date(this.expires_at).toLocaleString()} (${expiresAt}ms)`)

    this.idToken = session.idToken.jwtToken

    let email = session.idToken.payload.email.trim().toLowerCase()

    store.commit(StoreConstants.commits.setLoggedInEmail, email)

    let provider = session.idToken.payload.iss
    if(provider.startsWith('https://')) {
      provider = provider.replace('https://', '')
    }

    await this.initAwsBaseAwait(provider, this.idToken, this.expires_at)
  },

  getIdToken() {
    return this.idToken
  },

  async logoutAwait(reason, returnTo) {

    store.commit(StoreConstants.commits.setLoggedInEmail, null)

    if(AWS.config.credentials) {
      AWS.config.credentials.clearCachedId();
      AWS.config.credentials = null
    }

    await Auth.signOut({
      global: false
    })

    if(returnTo) {
      window.location = returnTo
    }



    // if(this.lock) {
    //   if(returnTo) {
    //     this.lock.logout({
    //       returnTo: returnTo
    //     })
    //   } else {
    //     this.lock.logout()
    //   }
    // }

    // window.location.href = window.location.origin + '/login'
  },
  isLoggedIn() {
    let idToken = this.getIdToken()
    return idToken !== null && idToken !== undefined
  }
}
