import createPersistedState from 'vuex-persistedstate'
import StoreConstants from './StoreConstants'
import api from './lib/api'
import Vue from 'vue'
import Vuex from 'vuex'
import E2EEHelperCache from "./services/e2ee/E2EEHelperCache";
import SegmentTracking from "./services/SegmentTracking";
import AndroidInterface from "@/services/AndroidInterface";

Vue.use(Vuex)

const lastTermsUpdate = 1556255482022; // April 25, 2019

let accountLoadedResolve
let accountLoadedPromise = new Promise((resolve) => {
  accountLoadedResolve = resolve
})

function buildSetupProgressObject(account) {
  if(!account || !account.account) {
    return
  }

  let hasCompletedAddDevicesStep = false
  let identities = account.account.identities
  if(identities) {
    for(let i of identities) {
      if(i.takeSnapshots) {
        hasCompletedAddDevicesStep = true
        break
      }
    }
  }

  let hasCompletedAddAccountabilityPartner = false
  let emails = account.account.emails
  if(emails && emails.length > 0) {
    hasCompletedAddAccountabilityPartner = true
  }

  let hasEnabledE2EE = false
  let e2eeActions = account.account.e2eeActions
  if(e2eeActions && e2eeActions.length > 0) {
    if(e2eeActions[e2eeActions.length - 1].action === 'disabled') {
      hasEnabledE2EE = false
    } else {
      hasEnabledE2EE = true
    }
  }

  //released this on 11/4/21 (1635984000000), so anyone creating an account before then just assume they've already done it
  const hasCompletedOnboardingSurvey = account.account.createdTimestamp < 1635984000000 ||
    (!!account.account.surveyResponses && !!account.account.surveyResponses.lastCompletedOnboardingSurvey)

  account.account.setupProgress = {
    // minimumSet: hasCompletedAddDevicesStep && hasCompletedAddAccountabilityPartner && hasEnabledE2EE,
    onboardingSurvey: hasCompletedOnboardingSurvey,
    minimumSet: hasCompletedAddDevicesStep && hasCompletedAddAccountabilityPartner && hasEnabledE2EE,
    addDevices: hasCompletedAddDevicesStep,
    addAccountabilityPartner: hasCompletedAddAccountabilityPartner,
    e2ee: hasEnabledE2EE,
    lastSet: Date.now()
  }
}

const store = new Vuex.Store({
  plugins: [
    createPersistedState({
      key: 'vuexpersistedstate',
      paths: ['referrer', 'href', 'keepMeLoggedIn', 'viewSettings', 'submitAllAutomatically']
    })
  ],
  state: {
    href: null,
    referrer: null,
    keepMeLoggedIn: null,
    account: null,
    devices: null,
    googleUser: null,
    isMobile: false,
    browser: null,
    inApp: false,
    appVersion: undefined,
    inAppInstalledFromGoogle: false,
    loadedFromAndroidApp: false,
    credentials: null,
    loggedInEmail: null,
    deviceUUID: null,
    inAppCredentialsExpiresAt: null,
    viewSettings: {
      blurHighRiskScreenshots: false
    },
    accountLoadedPromise: accountLoadedPromise,
    submitAllAutomatically: false,
    hasMouse: window.matchMedia('(any-pointer: fine)').matches, //see https://stackoverflow.com/questions/7838680/detecting-that-the-browser-has-no-mouse-and-is-touch-only#52854585
    hasTouch: window.matchMedia('(any-pointer: coarse)').matches
  },
  getters: {
    shouldBlockReportAccess: state => {
      if(!state.account || !state.account.account) {
        return false
      }
      if(state.account.os !== 'web') {
        return false
      }

      return !!state.account.account.blockReports
    },
    shouldAskForFeedback: state => {
      if(process.env.VUE_APP_HOST.indexOf('bestaccountability.com') !== -1) {
        return false
      }
      else if(!state.account || !state.account.account || state.account.account.product === 'bestaccountability') {
        return false
      }
      try{
        const accountIsThirtyDaysOld = state.account.account.createdTimestamp < Date.now() - 30 * 24 * 60 * 60 * 1000

        const isAccountSubscribed = state.account.account.accountSettings && state.account.account.accountSettings.premium > 0
        const isAccountabilityPartner = state.account.account.emailRecipientFor && state.account.account.emailRecipientFor.length > 0
        const isUsingAppAlready = isAccountSubscribed || isAccountabilityPartner

        //only ask once the account is thirty days old
        if(!state.account.account.surveyResponses || !state.account.account.surveyResponses.lastCompletedFeedbackSurvey) {
          return accountIsThirtyDaysOld && isUsingAppAlready
        }

        if(state.account.account.surveyResponses.lastCompletedFeedbackSurvey < Date.now() - 180 * 24 * 60 * 60 * 1000) {
          return isUsingAppAlready
        }
      } catch(e) {
        console.error(e)
      }
      return false
    },
    isOwner: state => {
      if(!state.account) {
        return false
      }

      return state.account.deviceType === 'OWNER'
    },
    getSubscriptionLastModified: state => {
      if(!state.account) {
        return null;
      }

      return state.account.account.accountSettings.lastModified
    },
    getSubscriptionAllowedMonitoredDeviceCount: state => {
      if(!state.account || !state.account.account || !state.account.account.accountSettings) {
        return 0;
      }
      return state.account.account.accountSettings.totalDevices
    },
    isCurrentSubscriptionPlanAvailable: state => {
      if(!state.account || !state.account.account || !state.account.account.accountSettings) {
        return null;
      }

      if(state.account.account.accountSettings.totalDevices >= 15) {
        return true
      }
      return false
    },
    getSubscriptionPlan: state => {
      if(!state.account) {
        return null;
      }

      if(state.account.account.accountSettings.subscriptionName) {
        if(state.account.account.accountSettings.totalDevices >= 15 && state.account.account.accountSettings.subscriptionInterval) {
          return `Truple ${state.account.account.accountSettings.subscriptionInterval === 'annual' ? 'Annual' : 'Monthly' } Plan`
        }
        return `${state.account.account.accountSettings.subscriptionName}`
      }

      return null
    },
    isSubscriptionAutoRenewalDisabled: state => {
      if(!state.account) {
        return null;
      }
      if(state.account && state.account.account && state.account.account.accountSettings) {
        return state.account.account.accountSettings.autoRenewalDisabled
      }
      return false
    },
    canUseFreeTrial: state => {
      if(!state.account) {
        return true
      } else {
        return state.account && state.account.account && (!state.account.account.stripeInfo || state.account.account.stripeInfo.length === 0)
      }
    },
    shouldGetFreeTrialDueToReferrer: (state, getters) => {
      if(!state.referrer) {
        return false
      } else if(!getters.canUseFreeTrial) {
        return false
      } else {

        const ref = state.referrer.toLowerCase()
        let words = ['fortsafety']

        for(let w of words) {
          if(ref.indexOf(w) !== -1) {
            return true
          }
        }

        return false
      }
    },
    isAccountLocked: state => {
      if(!state.account) {
        return null
      }

      return state.account.account.locked
    },
    getAccountUnlocker: state => {
      if(!state.account || !state.account.account.accountUnlocker) {
        return null
      }

      return state.account.account.accountUnlocker.contact
    },
    termsAccepted: state => {
      if(!state.account) //account not loaded
        return true

      if(state.account.account.termsAccepted && state.account.account.termsAccepted.length > 0) {
        let acceptanceTimestamp = state.account.account.termsAccepted[state.account.account.termsAccepted.length - 1].timestamp

        if(acceptanceTimestamp >= lastTermsUpdate) {
          return true
        }
      }
      return false
    },
    getDevices: state => {
      if(!state.devices) {
        return []
      }

      return state.devices
    },
    insufficientSubscription: state => {
      if(!state.account || !state.devices) {
        return false;
      }

      if(!state.account || !state.account.account || !state.account.account.accountSettings || !state.account.account.accountSettings) {
        return true
      }

      let currentCount = 0;
      state.devices.forEach(x => {
        if(x.takeSnapshots) {
          currentCount++;
        }
      })

      return currentCount > state.account.account.accountSettings.totalDevices
    },
    isE2EEEnabled: state => {
      if(!state.account) {
        return null;
      }

      return state.account.account.setupProgress.e2ee
    },
    isAccountabilityPartner: state => {
      if(!state.account) {
        return null;
      }

      if(state.account && state.account.account && state.account.account.emailRecipientFor) {
        return state.account.account.emailRecipientFor.length > 0
      }
      return false
    },
    isAccountPastDue: state => {
      if(!state.account) {
        return null;
      }

      if(state.account && state.account.account && state.account.account.accountSettings && state.account.account.accountSettings.status) {
        return state.account.account.accountSettings.status === "open" || state.account.account.accountSettings.status.startsWith('billing issue')
      }
      return false
    },
    hasSubmittedRatingsInLastDay: state => {
      if(!state.account) {
        return null;
      }

      if(state.account && state.account.account && state.account.account.lastSubmittedRatings) {
        return state.account.account.lastSubmittedRatings > Date.now() -  24 * 60 * 60 * 1000
      }
      return false
    },
    isSubscribed: state => {
      if(!state.account) {
        return null;
      }

      if(state.account && state.account.account && state.account.account.accountSettings) {
        return state.account.account.accountSettings.premium > 0
      }
    }
  },
  mutations: {
    [StoreConstants.commits.setSubmitAllAutomatically] (state, val) {
      state.submitAllAutomatically = val
    },
    [StoreConstants.commits.setUnknown] (state, val) {
      state.unknown = val
    },
    [StoreConstants.commits.setViewSettings] (state, val) {
      state.viewSettings = val
    },
    [StoreConstants.commits.setHref] (state, val) {
      if(val && (!state.href || state.href === 'null' || state.href === 'undefined')) {
        state.href = val
      }
    },
    [StoreConstants.commits.setReferrer] (state, val) {
      if(val) {
        val = val.toLowerCase()

        if((!state.referrer || state.referrer === 'null') &&
          !val.startsWith('https://app.truple.io') &&
          !val.startsWith('https://truple.io') &&
          !val.startsWith('https://dev.app.truple.io') &&
          !val.startsWith('https://app.bestaccountability.com') &&
          !val.startsWith('https://bestaccountability.com') &&
          !val.startsWith('https://dev.app.bestaccountability.com') &&
          !val.startsWith('http://localhost') &&
          !val.startsWith('https://accounts.google.com') &&
          !val.startsWith('https://accounts.youtube.com')) {
          //always set the referrer if no referrer has been set
          state.referrer = val
        }

        let domainsToCheck = ['https://start.truple.io', 'https://fortsafety.com']

        for(let s of domainsToCheck) {
          //override the referrer if one of these select domains is now the referrer
          if(val.toLowerCase().startsWith(s)) {
            state.referrer = val
          }
        }
      }
    },
    [StoreConstants.commits.setKeepMeLoggedIn] (state, val) {
      state.keepMeLoggedIn = val
    },
    [StoreConstants.commits.setInAppCredentialsExpiresAt] (state, expiresAt) {
      state.inAppCredentialsExpiresAt = expiresAt
    },
    [StoreConstants.commits.setCredentials] (state, credentials) {
      state.credentials = credentials
    },
    [StoreConstants.commits.setDeviceUUID] (state, deviceUUID) {
      state.deviceUUID = deviceUUID
    },
    [StoreConstants.commits.setLoggedInEmail] (state, loggedInEmail) {
      if(loggedInEmail) {
        loggedInEmail = loggedInEmail.toLowerCase()
      }
      state.loggedInEmail = loggedInEmail
    },
    [StoreConstants.commits.setBrowser] (state, browser) {
      state.browser = browser
    },
    [StoreConstants.commits.setMobile] (state, isMobile) {
      state.isMobile = isMobile
    },
    [StoreConstants.commits.setAccount] (state, account) {

      buildSetupProgressObject(account)

      state.account = account

      if(account.account) {
        E2EEHelperCache.getE2EEHelper(account.account.accountId, account.account.altAccountId)
        accountLoadedResolve()
      }
    },
    [StoreConstants.commits.setDevices] (state, devices) {
      state.devices = devices
    },
    [StoreConstants.commits.setLoadedFromAndroidApp] (state, val) {
      state.loadedFromAndroidApp = val
    },
    [StoreConstants.commits.showOwnScreenshots] (state, val) {
      state.showOwnScreenshots = val
    },
    [StoreConstants.commits.setInApp] (state) {
      state.inApp = true
      if(AndroidInterface.installedFromGooglePlay()) {
        state.inAppInstalledFromGoogle = true
      } else {
        state.inAppInstalledFromGoogle = false
      }
      try {
        if(AndroidInterface.getAppVersion) {
          const appVersion = AndroidInterface.getAppVersion()
          state.appVersion = appVersion ? parseInt(appVersion) : undefined
        }
      } catch(e) {
        console.log(e)
      }
    }
  },
  actions: {
    async [StoreConstants.actions.loadAccount] ({ commit }) {
      try {
        let response = await api.getAccountAwait(true)

        if(response && response.data) {

          if(response.data.account && response.data.account.altAccountId) {
            SegmentTracking.identify(response.data.account.altAccountId)
          }

          commit(StoreConstants.commits.setAccount, response.data)

        }
      } catch(error) {
        console.log(error)
        throw error
      }
    },
    async [StoreConstants.actions.loadDevices] ({ commit }) {
      try {
        let response = await api.getDevicesAwait()
        if(response && response.data) {
          commit(StoreConstants.commits.setDevices, response.data)
        }
      } catch(error) {
        console.log(error)
        throw error
      }
    }
  }
})

export default store
