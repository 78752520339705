class MobileBackButtonHelper {
  constructor() {
    this.beforeEach = []
  }

  registerVueRouter(vueRouter) {
    vueRouter.beforeEach((to, from, next) => {

      if(this.beforeEach && this.beforeEach.length > 0) {
        for(let c = this.beforeEach.length - 1; c >= 0; c--) {
          let beforeEach = this.beforeEach[c]
          let shouldReturn = false
          beforeEach(to, from, (val) => {
            if(!val) {
              shouldReturn = true
            }
            next(val)
          })

          if(shouldReturn) {
            console.log('vr.beforeEach false')
            return
          }
        }
      }

      console.log('vr.beforeEach true')
      next()
    })
  }

  addBeforeEach(func) {
    console.log('beforeEach added')
    this.beforeEach.push(func)

    return () => {
      this._removeBeforeEach(func)
    }
  }

  _removeBeforeEach(func) {
    let starting = this.beforeEach.length
    this.beforeEach = this.beforeEach.filter(x => {
      return x !== func
    })
    let ending = this.beforeEach.length
    console.log(`beforeEach removed ${starting} ${ending}`)
  }
}

export default (new MobileBackButtonHelper())