import Vue from 'vue'
import Vuetify from 'vuetify/lib'
// import 'vuetify/dist/vuetify.min.css'

const opts = {
  icons: {
    iconfont: 'md'
  },
  theme: {
    dark: false,
    themes: {
      light: {
        primary: '#00796B',
        secondary: '#757575',
        accent: '#FFC107',
        error: '#b71c1c',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#F4511E',
      },
      dark: {
        primary: '#00796B',
        secondary: '#757575',
        accent: '#FFC107',
        error: '#b71c1c',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#F4511E',
      }
    }
  }
}

Vue.use(Vuetify)

export default new Vuetify(opts);
