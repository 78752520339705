<template>
  <v-dialog v-model="showDialog" persistent max-width="600px" :fullscreen="$store.state.isMobile" style="z-index: 1001 !important;">
    <v-card>
      <v-card-title class="primary">
        <span class="white--text title">Review & Agree</span>
      </v-card-title>
      <v-card-text class="grow">
        <v-container fluid fill-height class="pa-0 ma-0 pt-5">
          <v-layout row wrap v-if="shouldAskForMarketingAndFeedback">
            <v-col :cols="12" class="mb-2 pa-2">
              <div class="title">Terms of Use</div>
              <div class="caption" style="float: right;"></div>
              <a href="https://truple.io/termsofuse.html" target="_blank">Click here to view</a>
            </v-col>
            <v-col :cols="12" class="mb-2 pa-2">
              <div class="title">Privacy Policy</div>
              <div class="caption" style="float: right;"></div>
              <a href="https://truple.io/privacy.html" target="_blank">Click here to view</a>
            </v-col>

            <template v-if="shouldAskForMarketingAndFeedback">
              <v-col :cols="12" sm6 class="pa-2">
                <b>Would you like to be notified of new features, offerings, and discounts?</b>
                <v-radio-group v-model="marketing" :column="false" class="pa-0 ma-0"
                   :error-messages=" marketing === null ? ['required'] : []">
                  <v-radio label="yes" value="yes"></v-radio>
                  <v-radio label="no" value="no" class="ml-2"></v-radio>
                </v-radio-group>
                <v-alert dense :type="marketing === 'yes' ? 'success' : 'error'" v-if="marketing !== null && $store.state.account && $store.state.account.account && $store.state.account.account.createdTimestamp && $store.state.account.account.createdTimestamp > Date.now() - 7 * 24 * 60 * 60 * 1000 && $store.state.inApp">
                  <template v-if="marketing === 'yes'">
                    Check your email inbox for a $3 discount code!
                  </template>
                  <template v-else>
                    You won't receive an immediate $3 discount code.
                  </template>
                </v-alert>
              </v-col>
<!--              <v-col :cols="12" :sm="6" class="pa-2">-->
<!--                <b>Would you be willing answer questions about your experience with Truple?</b>-->
<!--                <v-radio-group v-model="feedback" :column="false" class="pa-0 ma-0"-->
<!--                   :error-messages="feedback === null ? ['required'] : []">-->
<!--                  <v-radio label="yes" value="yes"></v-radio>-->
<!--                  <v-radio label="no" value="no" class="ml-2"></v-radio>-->
<!--                </v-radio-group>-->
<!--              </v-col>-->
            </template>
          </v-layout>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="decline()" text color="secondary" :disabled="accepting" outlined >Decline</v-btn>
        <v-spacer></v-spacer>
        <v-btn @click="accept()" color="primary" :disabled="accepting || (marketing === null || feedback === null)">
          <span class="pr-1">Agree</span>
          <v-progress-circular v-if="accepting" :indeterminate="true" :value="0" size="24" class="ml-2"></v-progress-circular>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import StoreConstants from '../StoreConstants'
import api from '../lib/api'
import auth from '../lib/auth'

  const shouldAskForMarketingAndFeedback = true

  export default {
    name: "TermsOfService",
    data () {
      return {
        accepting: false,
        shouldAskForMarketingAndFeedback: shouldAskForMarketingAndFeedback,
        marketing: null, //shouldAskForMarketingAndFeedback ? null : 'yes',
        feedback: 'yes' //shouldAskForMarketingAndFeedback ? null : 'yes'
      }
    },
    methods: {
      async accept() {
        try {
          this.accepting = true

          let marketingBool = this.marketing === 'yes' ? true : false
          let feedbackBool = this.feedback === 'yes' ? true : false

          let success = await api.acceptTerms(marketingBool, feedbackBool)
          let accountResponse = await api.getAccountAwait(true)

          this.$store.commit(StoreConstants.commits.setAccount, accountResponse.data)
        } catch(error) {
          console.log(error)
          this.$swal('error accepting terms, please email support@truple.io for help.')
        } finally {
          this.accepting = false
        }
      },
      async decline() {
        // alert('You must accept if you wish to continue using Truple.')
        let val = await this.$swal({
          title: 'Decline',
          text: 'Do you wish to decline and logout from Truple?',
          showCancelButton: true
        })

        if(val.isConfirmed) {
          await auth.logoutAwait('terms-declined')
          location.reload()
        }
      }
    },
    computed: {
      show() {
        return !this.$store.getters.termsAccepted
      },
      showDialog: {
        get() {
          return this.show
        },
        set() {
          //do nothing
        }
      }
    }
  }
</script>

<style scoped lang="less">

</style>
