var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-tooltip",
        {
          attrs: {
            top: _vm.$store.state.isMobile,
            right: !_vm.$store.state.isMobile,
            dark: "",
            color: "rgba(0, 0, 0, 0.95)"
          },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function(ref) {
                var on = ref.on
                return [
                  _c(
                    "v-text-field",
                    _vm._g(
                      {
                        attrs: {
                          outlined: "",
                          label: "Password",
                          placeholder: "",
                          error:
                            !_vm.passwordIsValidWithoutConfirm &&
                            !!_vm.myAuthData.password,
                          autocomplete: "",
                          type: _vm.showPassword ? "text" : "password",
                          "append-icon": _vm.showPassword
                            ? "visibility_off"
                            : "visibility",
                          disabled: _vm.disabled
                        },
                        on: {
                          change: _vm.authDataChange,
                          animationstart: _vm.authDataChange,
                          "click:append": _vm.togglePasswordVisibility
                        },
                        model: {
                          value: _vm.myAuthData.password,
                          callback: function($$v) {
                            _vm.$set(_vm.myAuthData, "password", $$v)
                          },
                          expression: "myAuthData.password"
                        }
                      },
                      on
                    )
                  )
                ]
              }
            }
          ])
        },
        [
          _c("div", [
            _c("div", { staticClass: "text--black" }, [
              _vm._v("Your password should contain:")
            ]),
            _c(
              "div",
              {
                class: {
                  "text--theme--primary": _vm.passwordLongEnough,
                  "text--theme--warning": !_vm.passwordLongEnough
                }
              },
              [_vm._v("\n        • length of 8+\n      ")]
            ),
            _c(
              "div",
              {
                class: {
                  "text--theme--primary": _vm.passwordHasLowerCase,
                  "text--theme--warning": !_vm.passwordHasLowerCase
                }
              },
              [_vm._v("\n        • lowercase letters\n      ")]
            ),
            _c(
              "div",
              {
                class: {
                  "text--theme--primary": _vm.passwordHasUpperCase,
                  "text--theme--warning": !_vm.passwordHasUpperCase
                }
              },
              [_vm._v("\n        • uppercase letters\n      ")]
            ),
            _c(
              "div",
              {
                class: {
                  "text--theme--primary": _vm.passwordHasNumber,
                  "text--theme--warning": !_vm.passwordHasNumber
                }
              },
              [_vm._v("\n        • numbers\n      ")]
            ),
            _c(
              "div",
              {
                class: {
                  "text--theme--primary": _vm.passwordHasSpecialCharacter,
                  "text--theme--warning": !_vm.passwordHasSpecialCharacter
                }
              },
              [_vm._v("\n        • special character\n      ")]
            )
          ])
        ]
      ),
      _vm.showConfirm
        ? _c("v-text-field", {
            attrs: {
              color:
                _vm.confirmPassword !== _vm.myAuthData.password
                  ? "warning"
                  : "primary",
              outlined: "",
              label: "Confirm Password",
              placeholder: "",
              type: _vm.showPassword ? "text" : "password",
              hint:
                _vm.confirmPassword !== _vm.myAuthData.password
                  ? "Passwords don't match"
                  : "",
              "append-icon": _vm.showPassword ? "visibility_off" : "visibility",
              "persistent-hint": true,
              error: _vm.confirmPassword !== _vm.myAuthData.password,
              disabled: _vm.disabled
            },
            on: { "click:append": _vm.togglePasswordVisibility },
            model: {
              value: _vm.confirmPassword,
              callback: function($$v) {
                _vm.confirmPassword = $$v
              },
              expression: "confirmPassword"
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }