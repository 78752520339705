var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "amplify-card",
    [
      _c("v-card-title", [_vm._v("\n      Password reset\n    ")]),
      _c(
        "v-card-text",
        [
          _c(
            "div",
            { staticClass: "mb-6" },
            [
              _c("p", [
                _vm._v(
                  "Enter your email below and we will send a message to reset your password."
                )
              ]),
              _c(
                "v-expand-transition",
                [
                  _c(
                    "v-alert",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.gmailErrorClass,
                          expression: "gmailErrorClass"
                        }
                      ],
                      attrs: {
                        type: "secondary",
                        icon: "info",
                        outlined: "",
                        border: "left"
                      }
                    },
                    [
                      _c("div", [
                        _vm._v(
                          "If you do not receive an email it's likely because you logged in using your Google Account."
                        )
                      ]),
                      _c("div", { staticClass: "pt-2" }, [
                        _vm._v(
                          '\n              Please login using the "Sign in with Google" button.\n            '
                        )
                      ]),
                      _c(
                        "div",
                        { staticClass: "d-flex pt-2 pb-2" },
                        [_c("SignInWithGoogle", { staticClass: "mx-auto" })],
                        1
                      ),
                      _c("div"),
                      _c("div", [
                        _vm._v(
                          "If you need to reset your google account password, see "
                        ),
                        _c(
                          "a",
                          {
                            attrs: {
                              href:
                                "https://support.google.com/accounts/answer/41078",
                              target: "_blank"
                            }
                          },
                          [_vm._v("Google's password recovery page")]
                        ),
                        _vm._v(".")
                      ])
                    ]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("v-text-field", {
            attrs: {
              outlined: "",
              label: "Email Address",
              placeholder: "",
              type: "email",
              autocomplete: "",
              disabled: _vm.loading,
              autofocus: ""
            },
            on: { change: _vm.authDataChange },
            model: {
              value: _vm.myAuthData.email,
              callback: function($$v) {
                _vm.$set(_vm.myAuthData, "email", $$v)
              },
              expression: "myAuthData.email"
            }
          }),
          _c(
            "div",
            { staticClass: "d-flex", staticStyle: { width: "100%" } },
            [
              _c(
                "div",
                { staticClass: "d-flex align-center" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "pl-2 text-none",
                      staticStyle: {
                        cursor: "pointer",
                        "letter-spacing": "normal"
                      },
                      attrs: { text: "", color: "primary" },
                      on: { click: _vm.goToSignIn }
                    },
                    [_vm._v("Sign In")]
                  )
                ],
                1
              ),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: {
                    color: "primary",
                    large: "",
                    disabled: _vm.loading || !_vm.myAuthData.email,
                    loading: _vm.loading
                  },
                  on: { click: _vm.submitForgot }
                },
                [_vm._v("\n          Reset Password\n        ")]
              )
            ],
            1
          ),
          _c(
            "v-dialog",
            {
              attrs: {
                "max-width": "600",
                fullscreen: _vm.$store.state.isMobile
              },
              model: {
                value: _vm.confirmDialog,
                callback: function($$v) {
                  _vm.confirmDialog = $$v
                },
                expression: "confirmDialog"
              }
            },
            [
              _c(
                "v-card",
                [
                  _c("v-card-title", [_vm._v("Forgot Password")]),
                  _c(
                    "v-card-text",
                    [
                      _c("div", { staticClass: "mb-6" }, [
                        _vm._v(
                          "\n              We've sent a reset code to your inbox.  Enter it below to update your password.  If you don't receive a code and you have a Google account, try logging in using the \"Sign in with Google\" button.\n            "
                        )
                      ]),
                      _c("v-text-field", {
                        attrs: {
                          outlined: "",
                          autofocus: "",
                          label: "Code",
                          placeholder: "",
                          disabled: _vm.loading,
                          error: !_vm.myAuthData.code,
                          name: Math.random()
                        },
                        on: { change: _vm.authDataChange },
                        model: {
                          value: _vm.myAuthData.code,
                          callback: function($$v) {
                            _vm.$set(_vm.myAuthData, "code", $$v)
                          },
                          expression: "myAuthData.code"
                        }
                      }),
                      _c("password-text-field", {
                        attrs: {
                          "auth-data": _vm.myAuthData,
                          "show-confirm": true,
                          disabled: _vm.loading
                        },
                        on: {
                          "auth-data-change": _vm.authDataChange,
                          "on-password-valid-change": _vm.passwordValidChange
                        }
                      }),
                      _c(
                        "div",
                        {
                          staticClass: "d-flex",
                          staticStyle: { width: "100%" }
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "d-flex align-center" },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "pl-2 text-none",
                                  staticStyle: {
                                    cursor: "pointer",
                                    "letter-spacing": "normal"
                                  },
                                  attrs: { text: "", color: "primary" },
                                  on: { click: _vm.goToSignIn }
                                },
                                [_vm._v("Sign In")]
                              )
                            ],
                            1
                          ),
                          _c("v-spacer"),
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                color: "primary",
                                large: "",
                                disabled:
                                  !_vm.myAuthData.code ||
                                  !_vm.isPasswordValid ||
                                  _vm.loading,
                                loading: _vm.loading
                              },
                              on: { click: _vm.submitConfirm }
                            },
                            [
                              _vm._v(
                                "\n                Change Password\n              "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }