<template>
  <amplify-card>
    <v-card-title>
      Password reset
    </v-card-title>
    <v-card-text>
      <div class="mb-6">
        <p>Enter your email below and we will send a message to reset your password.</p>
        <v-expand-transition>
          <v-alert type="secondary" icon="info" outlined border="left" v-show="gmailErrorClass">
            <div>If you do not receive an email it's likely because you logged in using your Google Account.</div>
            <div class="pt-2">
              Please login using the "Sign in with Google" button.
            </div>
            <div class="d-flex pt-2 pb-2">
              <SignInWithGoogle class="mx-auto" />
            </div>
            <div></div>
            <div>If you need to reset your google account password, see <a href="https://support.google.com/accounts/answer/41078" target="_blank">Google's password recovery page</a>.</a> </div>
          </v-alert>
        </v-expand-transition>
      </div>
      <v-text-field
          outlined
          label="Email Address"
          placeholder=""
          type="email"
          autocomplete
          v-model="myAuthData.email"
          @change="authDataChange"
          :disabled="loading"
          autofocus
      />

      <div style="width: 100%;" class="d-flex">
        <div class="d-flex align-center">
          <v-btn text @click="goToSignIn" class="pl-2 text-none" color="primary" style="cursor: pointer; letter-spacing: normal;">Sign In</v-btn>
        </div>
        <v-spacer/>
        <v-btn color="primary" @click="submitForgot" large :disabled="loading || !myAuthData.email" :loading="loading">
          Reset Password
        </v-btn>
      </div>

      <v-dialog v-model="confirmDialog" max-width="600" :fullscreen="$store.state.isMobile">
        <v-card>
          <v-card-title>Forgot Password</v-card-title>
          <v-card-text>
            <div class="mb-6">
              We've sent a reset code to your inbox.  Enter it below to update your password.  If you don't receive a code and you have a Google account, try logging in using the "Sign in with Google" button.
            </div>

            <v-text-field
                outlined
                autofocus
                label="Code"
                placeholder=""
                :disabled="loading"
                v-model="myAuthData.code"
                @change="authDataChange"
                :error="!myAuthData.code"
                :name="Math.random()"
            />
<!--           :name="Math.random()" prevents autofill-->

            <password-text-field
                :auth-data="myAuthData"
                @auth-data-change="authDataChange"
                @on-password-valid-change="passwordValidChange"
                :show-confirm="true"
                :disabled="loading"
            />

            <div style="width: 100%;" class="d-flex">
              <div class="d-flex align-center">
                <v-btn text @click="goToSignIn" class="pl-2 text-none" color="primary" style="cursor: pointer; letter-spacing: normal;">Sign In</v-btn>
              </div>
              <v-spacer/>
              <v-btn color="primary" @click="submitConfirm" large :disabled="!myAuthData.code || !isPasswordValid || loading" :loading="loading">
                Change Password
              </v-btn>
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>

    </v-card-text>
  </amplify-card>
</template>

<script>
import Auth from '@aws-amplify/auth'
import AuthPages from "@/components/amplify/AuthPages"
import AmplifyCard from "@/components/amplify/AmplifyCard";
import PasswordTextField from "@/components/amplify/PasswordTextField";
import SignInWithGoogle from "@/components/amplify/SignInWithGoogle";

export default {
name: "ForgotPassword",
  components: {
    SignInWithGoogle,
    AmplifyCard,
    PasswordTextField
  },
  props: {
    authData: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      loading: false,
      confirmDialog: false,
      isPasswordValid: true,
      myAuthData: this.authData
    }
  },
  computed: {
    gmailErrorClass() {
      if(this.myAuthData && this.myAuthData.email && (this.myAuthData.email.toLowerCase().endsWith('@gmail.com') || this.myAuthData.email.toLowerCase().endsWith('@googlemail.com'))) {
        return true
      }
      return false
    }
  },
  methods: {
    goToSignIn() {
      this.$emit('page', AuthPages.signIn)
    },
    authDataChange() {
      this.$emit('auth-data-change', this.myAuthData)
    },
    async submitForgot() {
      this.loading = true
      try {
        await Auth.forgotPassword(this.myAuthData.email)
        this.confirmDialog = true
      } catch(e) {
        this.myAuthData.error = e
        this.authDataChange()
      }
      this.loading = false
    },
    async submitConfirm() {
      this.loading = true
      try {
        await Auth.forgotPasswordSubmit(
            this.myAuthData.email,
            this.myAuthData.code,
            this.myAuthData.password
        )
        this.confirmDialog = false
        this.myAuthData.code = ''
        this.authDataChange()

        this.goToSignIn()
      } catch(e) {
        console.log(e)
        this.myAuthData.error = e
        this.authDataChange()
      }
      this.loading = false
    },
    passwordValidChange(val) {
      console.log(`passwordValidChange fpc ${val}`)
      this.isPasswordValid = val
    },
  }
}
</script>

<style scoped>

</style>