<template>
  <div>
    <slot></slot>
    <div class="d-flex mt-3">
      <v-btn v-if="backButtonText" @click="back" text :disabled="nextLoading">{{backButtonText}}</v-btn>
      <v-spacer />
      <v-btn color="primary" v-if="nextButtonText" :disabled="nextDisabled || nextLoading" :loading="nextLoading" @click="next">{{nextButtonText}}</v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: "SurveyQuestion",
  props: {
    backButtonText: {
      type: String,
      default: 'Back'
    },
    nextButtonText: {
      type: String,
      default: 'Next'
    },
    nextDisabled: {
      type: Boolean,
      default: false
    },
    nextLoading: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    next() {
      this.$emit('on-next')
    },
    back() {
      this.$emit('on-back')
    }
  }
}
</script>

<style scoped>

</style>