<template>
  <div style="width: 100%; display: flex; justify-content: center; align-items: center;">
    <sign-in-with-google @clicked="clicked"/>
  </div>
</template>

<script>
import SignInWithGoogle from "@/components/amplify/SignInWithGoogle";

export default {
  name: "GoogleOAuthButton",
  components: {SignInWithGoogle},
  methods: {
    clicked() {
      this.$emit('on-trigger')
    }
  }
}
</script>

