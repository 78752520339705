var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mt-6" },
    [
      _c("div", { staticClass: "title" }, [_vm._v(_vm._s(_vm.question))]),
      _c(
        "v-btn",
        {
          staticClass: "mb-4 mt-4",
          attrs: { block: "", outlined: _vm.value !== "yes", color: "primary" },
          on: { click: _vm.yesClicked }
        },
        [_vm._v("Yes")]
      ),
      _c(
        "v-btn",
        {
          staticClass: "mb-4 mt-4",
          attrs: { block: "", outlined: _vm.value !== "no", color: "primary" },
          on: { click: _vm.noClicked }
        },
        [_vm._v("No")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }