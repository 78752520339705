var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container", on: { click: _vm.clicked } }, [
    _c("img", {
      staticClass: "logo",
      attrs: { src: "/static/img/btn_google_dark_normal_ios.svg" }
    }),
    _vm._m(0)
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "txt noselect" }, [
      _c("span", { staticStyle: { "font-size": "0.875rem" } }, [
        _vm._v("Sign in with Google")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }