import surveyClientFactory from './apigClient'
import store from "@/store"

class SurveyApi {
  constructor() {
    this.inited = false
    this.client = null
  }

  init() {
    if(!this.inited) {
      this.inited = true
      console.log(process.env.VUE_APP_SURVEY_URL)
      this.client = surveyClientFactory.newClient(store.state.credentials, process.env.VUE_APP_SURVEY_URL)
    }
  }

  async submitSurveyResults(surveyCategory, surveyAnswers) {
    try {
      this.init()

      return await this.client.surveyPost({
        deviceUUID: store.state.deviceUUID
      }, {
        surveyCategory: surveyCategory,
        surveyAnswers: surveyAnswers
      })
    } catch(e) {
      console.error(e)
      throw e
    }
  }
}

export default (new SurveyApi())
