<template>
  <div class="mt-6">
    <div class="title">{{ question }}</div>
    <v-btn class="mb-4 mt-4" block :outlined="value !== 'yes'" color="primary" @click="yesClicked">Yes</v-btn>
    <v-btn class="mb-4 mt-4" block :outlined="value !== 'no'" color="primary" @click="noClicked">No</v-btn>
<!--    <v-btn text v-if="!req" block class="mx-auto" @click="skipClicked">Skip</v-btn>-->
  </div>
</template>

<script>
export default {
  name: "YesNo",
  props: {
    question: {
      type: String,
      required: true
    },
    req: {
      type: Boolean,
      default: false
    },
    value: {
    }
  },
  methods: {
    yesClicked() {
      this.$emit('input', 'yes')
    },
    noClicked() {
      this.$emit('input', 'no')
    },
    skipClicked() {
      this.$emit('input', 'skipped')
    }
  }
}
</script>

<style scoped>

</style>