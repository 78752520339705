<template>
  <div class="mt-6">
    <div class="title">{{ question }}</div>
    <div>
      <v-textarea outlined :autofocus="autofocus" v-model="response" @keyup="changed" />
    </div>
  </div>
</template>

<script>
export default {
  name: "LongText",
  props: {
    question: {
      type: String,
      required: true
    },
    value: {},
    autofocus: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      response: this.value
    }
  },
  methods: {
    changed() {
      this.$emit('input', this.response)
    }
  }
}
</script>

<style scoped>

</style>