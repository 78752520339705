import eventsClientFactory from "./apigClient";
import store from '../../store'
import AWSClockSkewHandler from "../AWSClockSkewHandler";

const requestIncrement = 15;

class EventsApi {

  constructor() {
    this.inited = false
  }

  init() {
    if(!this.inited) {
      this.inited = true
      console.log(process.env.VUE_APP_EVENTS_URL)
      this.client = eventsClientFactory.newClient(store.state.credentials, process.env.VUE_APP_EVENTS_URL)
    }
  }

  async getChunkOfEvents(accountId, deviceId, current, until) {
    let timesToLoad
    timesToLoad = Math.ceil((current - until) / (requestIncrement * 60 * 1000))

    let error
    let promises = []
    for(let c = 0; c < timesToLoad; c++) {
      promises.push(this.getEvents(accountId, deviceId, current,
        current - requestIncrement * 60 * 1000,
        'hours')
        .catch(err => {
          error = err
          console.log('this error being hit')
        }))

      current -= (1 * requestIncrement * 60 * 1000)
    }
    if(error) throw error

    let tempResults = await Promise.all(promises).catch(err => {
      error = err
    })
    if(error) throw error

    let results = {
      data: {
        events: []
      }
    }

    for(let r of tempResults) {
      if(r && r.data && r.data.events) {
        for(let a of r.data.events) {
          results.data.events.push(a)
        }
      }
    }

    for(let event of results.data.events) {
      event.timestamp = parseInt(event.timestamp)
      event.timestampString = new Date(event.timestamp).toLocaleString()
      event.key = `${event.timestamp}.${event.eventType}.${event.riskRating}.${event.eventTitle}.${event.eventBody}`
    }

    return results.data.events
  }

  async getEvents(accountId, deviceId, start, end, unit) {
    this.init()

    let request = {
      deviceId: deviceId,
      start: start,
      end: end,
      unit: unit,
      deviceUUID: store.state.deviceUUID,
      forAccountId: accountId
    }

    let error
    let result = await AWSClockSkewHandler.handleClockSkew(() => {
      return this.client.eventsGet(request, {})
    })
    .catch(err => {
      error = err
    })
    if(error) throw error

    return result
  }

  async queryEventStats(request) {
    this.init()

    let error
    let result = await AWSClockSkewHandler.handleClockSkew(() => {
      return this.client.eventsStatsPost({
        deviceUUID: store.state.deviceUUID
      }, request)
    }).catch(err => {
      error = err
    })
    if(error) throw error

    return result
  }

}

export default (new EventsApi())
