<template>
  <v-container>
    <v-layout justify-center align-center>
      <v-progress-circular
              v-if="!dialog"
              indeterminate
              color="accent"
      ></v-progress-circular>
      <v-dialog v-model="dialog"
                hide-overlay
                persistent
                width="300">
        <v-card
                color="primary"
                dark
        >
          <v-card-text>
            {{ message ? message : 'Loading...'}}
            <v-progress-linear
                    indeterminate
                    color="white"
                    class="mb-0 mt-1"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-layout>
  </v-container>
</template>

<script>
  export default {
    name: "Loading",
    props: {
      message: {
        type: String,
        required: false
      },
      dialog: {
        type: Boolean,
        required: false,
        default: false
      },
      loading: {
        type: Boolean,
        required: false,
        default: true
      }
    },
    methods: {
    },
    data () {
      return {
        showDialog: false
      }
    },
    watch: {
      loading() {
        this.showDialog = this.$props.dialog
      }
    }
  }
</script>

<style scoped>

</style>