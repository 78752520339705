import E2EEHelper from "./E2EEHelper";

let cache = {}

class E2EEHelperCache {
  getE2EEHelper(accountId) {
    if(!cache[accountId]) {
      let helper = new E2EEHelper(accountId)
      cache[accountId] = helper
    }

    return cache[accountId]
  }

  getAllE2EEHelpers() {
    return Object.values(cache)
  }
}

export default (new E2EEHelperCache())