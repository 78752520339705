/**
 * Extends e3kit to add support for multi-card use
 */

import {
  IdentityAlreadyExistsError,
  MultipleCardsError,
  PrivateKeyAlreadyExistsError,
  RegisterRequiredError,
  UsersFoundWithMultipleCardsError,
  UsersNotFoundError
} from "@virgilsecurity/e3kit-base";

//copied from array.ts in e3kit-base
function chunkArray(array, size) {
  size = Math.max(size, 0);
  const length = array == null ? 0 : array.length;
  if (!length || size < 1) {
    return [];
  }
  let index = 0;
  let resIndex = 0;
  const result = Array(Math.ceil(length / size));

  while (index < length) {
    result[resIndex++] = array.slice(index, (index += size));
  }

  return result;
}

const MAX_IDENTITIES_TO_SEARCH = 50; //from e3kit-base constants.ts


class EThreeExtender {
  extend(eThree) {

    eThree.rotatePrivateKeyWithMultipleCards = async (cardFilter, extraFields = {}) => {
      if (eThree.inProcess) {
        eThree.throwIllegalInvocationError('rotatePrivateKey');
      }
      eThree.inProcess = true;
      try {
        const [tempCards, privateKey] = await Promise.all([
          eThree.cardManager.searchCards(eThree.identity),
          eThree.keyLoader.loadLocalPrivateKey(),
        ]);

        const cards = tempCards.filter(cardFilter);

        if (cards.length === 0) throw new RegisterRequiredError();
        if (cards.length > 1) throw new MultipleCardsError(eThree.identity);
        if (privateKey) throw new PrivateKeyAlreadyExistsError();
        await eThree.publishCardThenSavePrivateKeyLocalWithExtraFields({ previousCard: cards[0], extraFields });
      } finally {
        eThree.inProcess = false;
      }
    }

    eThree.publishCardThenSavePrivateKeyLocalWithExtraFields = async (options) => {
      const { keyPair, previousCard } = options;
      const myKeyPair = keyPair || eThree.virgilCrypto.generateKeys(eThree.keyPairType);
      const card = await eThree.cardManager.publishCard({
        privateKey: myKeyPair.privateKey,
        publicKey: myKeyPair.publicKey,
        previousCardId: previousCard ? previousCard.id : undefined,
        extraFields: options.extraFields,
      });
      await eThree.keyLoader.savePrivateKeyLocal(myKeyPair.privateKey);
      return {
        card,
        keyPair: myKeyPair,
      };
    }

    eThree.registerWithMultipleCards = async (keyPair, cardFilter, extraFields = {}) => {
      if (eThree.inProcess) {
        eThree.throwIllegalInvocationError('register');
      }
      eThree.inProcess = true;
      try {
        const [tempCards, privateKey] = await Promise.all([
          eThree.cardManager.searchCards(eThree.identity),
          eThree.keyLoader.loadLocalPrivateKey(),
        ]);

        const cards = tempCards.filter(cardFilter);

        if (cards.length > 1) throw new MultipleCardsError(eThree.identity);
        if (cards.length > 0) throw new IdentityAlreadyExistsError();
        if (privateKey) await eThree.keyLoader.resetLocalPrivateKey();
        await eThree.publishCardThenSavePrivateKeyLocalWithExtraFields({ keyPair, extraFields });
      } finally {
        eThree.inProcess = false;
      }
    }

    eThree.unregisterWithMultipleCards = async (cardFilter) => {
      if (eThree.inProcess) {
          eThree.throwIllegalInvocationError('unregister');
      }
      eThree.inProcess = true;
      try {
        let cards = await eThree.cardManager.searchCards(eThree.identity);

        if (cards.length === 0) {
          throw new RegisterRequiredError();
        }

        cards = cards.filter(cardFilter)

        for (const card of cards) {
          await eThree.cardManager.revokeCard(card.id);
        }
        await eThree.keyLoader.resetLocalPrivateKey();
        await eThree.onPrivateKeyDeleted();
      } finally {
        eThree.inProcess = false;
      }
    }

    /**
     * this is custom added by me!
     * @type {AsyncIterator}
     */
    eThree.revokeCardsForDeletedDevices = async (setOfDeviceIds) => {
      if (eThree.inProcess) {
        eThree.throwIllegalInvocationError('unregister');
      }
      eThree.inProcess = true;
      try {
        let cards = await eThree.cardManager.searchCards(eThree.identity);

        cards = cards.filter(card => {
          if(!card.isOutdated) {
            for(let signature of card.signatures) {
              if(signature.extraFields && setOfDeviceIds.has(signature.extraFields.identityId)) {
                // console.log('true')
                return true
              }
            }
          }

          return false
        })

        for (const card of cards) {
          // eslint-disable-next-line no-console
          console.log(`revoking card\n${JSON.stringify(card, null, 2)}`)
          await eThree.cardManager.revokeCard(card.id);
        }

      } finally {
        eThree.inProcess = false;
      }
    }

    eThree.getLocalPrivateKey = async () => {
      return eThree.keyLoader.loadLocalKeyPair()
    }

    eThree.getLocalPrivateKey().then(x => {
      eThree.localPrivateKey = x
    })

    eThree.findUsersWithCardFilter = async (identities, cardFilter) => {
      if (!identities) {
        throw new TypeError('Argument "identities" is required');
      }

      let identitySet;
      if (typeof identities === 'string') {
        identitySet = new Set([identities]);
      } else if (Array.isArray(identities)) {
        identitySet = new Set(identities);
      } else {
        throw new TypeError(
          `Expected "identities" to be a string or an array of strings. Got: "${typeof identities}"`,
        );
      }

      if (identitySet.size === 0) {
        throw new TypeError('"identities" array must not be empty');
      }

      const result = Object.create({});
      const identitiesWithMultipleCards = new Set();

      const identityChunks = chunkArray(Array.from(identitySet), MAX_IDENTITIES_TO_SEARCH);
      for (const identityChunk of identityChunks) {
        const cards = await eThree.cardManager.searchCards(identityChunk);
        for (const card of cards) {
          if (!cardFilter(card)) {
            continue;
          }
          if (result[card.identity]) {
            identitiesWithMultipleCards.add(card.identity);
          }
          result[card.identity] = card;
        }
      }

      const identitiesFound = new Set(Object.keys(result));
      const identitiesNotFound = new Set([...identitySet].filter(i => !identitiesFound.has(i)));
      if (identitiesNotFound.size > 0) {
        throw new UsersNotFoundError([...identitiesNotFound]);
      }

      if (identitiesWithMultipleCards.size > 0) {
        throw new UsersFoundWithMultipleCardsError([...identitiesWithMultipleCards]);
      }

      if (Array.isArray(identities)) {
        return result;
      }

      return result[identities];
    }

    return eThree
  }
}

export default (new EThreeExtender())