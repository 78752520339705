var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._t("default"),
      _c(
        "div",
        { staticClass: "d-flex mt-3" },
        [
          _vm.backButtonText
            ? _c(
                "v-btn",
                {
                  attrs: { text: "", disabled: _vm.nextLoading },
                  on: { click: _vm.back }
                },
                [_vm._v(_vm._s(_vm.backButtonText))]
              )
            : _vm._e(),
          _c("v-spacer"),
          _vm.nextButtonText
            ? _c(
                "v-btn",
                {
                  attrs: {
                    color: "primary",
                    disabled: _vm.nextDisabled || _vm.nextLoading,
                    loading: _vm.nextLoading
                  },
                  on: { click: _vm.next }
                },
                [_vm._v(_vm._s(_vm.nextButtonText))]
              )
            : _vm._e()
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }