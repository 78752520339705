import moment from "moment";
import Utils from '../Utils'
import eventsApi from "../aws/events/eventsApi";
import localForage from "localforage";
import AWSClockSkewHandler from "../aws/AWSClockSkewHandler";
import E2EEHelperCache from "./e2ee/E2EEHelperCache";
const store = localForage.createInstance({
  storeName: 'eventCache'
})

class EventCacheItem {
  constructor(data) {
    this.identity = data.identity
    this.startTime = data.startTime || null
    this.endTime = data.endTime || null
    this.events = data.events || []
    this.created = data.created || AWSClockSkewHandler.getTime()
  }

  isExpired() {
    return AWSClockSkewHandler.getTime() - this.created > 24 * 60 * 60 * 1000
  }
}

let keys = new Set()

function createKey(identity) {
  let dateString = moment().format('YYYYMMDD')
  return `${identity}.${dateString}.eci`
}

function isKeyExpired(key) {
  let s = key.split('.')
  let date = s[1]

  let diff = moment.duration(moment().diff(moment(date, "YYYYMMDD"))).asDays()

  if(diff > 1.0) {
    return true
  }
  return false
}

async function cacheItem(eci) {
  let key = createKey(eci.identity)
  keys.add(key)
  await store.setItem(key, eci)
}

async function getItemFromCache(identity) {
  let key = createKey(identity)

  let ret = await store.getItem(key)
  if(ret) {
    ret = new EventCacheItem(ret)
    if(!ret.isExpired()) {
      return ret
    }
  }

  return null

  // let raw = sessionStorage.getItem(`${identity}.eci`)
  // if(raw) {
  //   return new EventCacheItem(JSON.parse(raw))
  // } else {
  //   return null
  // }
}


async function decryptEvents(accountId, identityId, events) {
  let e2eeHelper = null

  // let allPromises = []
  // for(let c = 0; c < events.length; c++) {
  //   let event = events[c]
  for(let event of events) {
    if(event.encrypted) {

      if(e2eeHelper === null) {
        console.log('getting e2ee helper')
        e2eeHelper = E2EEHelperCache.getE2EEHelper(accountId)
        console.log('got it')
      }

      try {

        event.eventTitle = await e2eeHelper.decryptString(identityId, event.eventTitle, event.timestamp)
        event.eventBody = await e2eeHelper.decryptString(identityId, event.eventBody, event.timestamp)
        event.appName = await e2eeHelper.decryptString(identityId, event.appName, event.timestamp)
        event.packageName = await e2eeHelper.decryptString(identityId, event.packageName, event.timestamp)
        event.decrypted = true

        // let promiseArray = []
        // promiseArray.push(e2eeHelper.decryptString(identityId, event.eventTitle))
        // promiseArray.push(e2eeHelper.decryptString(identityId, event.eventBody))
        // promiseArray.push(e2eeHelper.decryptString(identityId, event.appName))
        // promiseArray.push(e2eeHelper.decryptString(identityId, event.packageName))
        //
        // allPromises.push(Promise.all(promiseArray).then(values => {
        //   console.log('ran')
        //
        //   event.eventTitle = values[0]
        //   event.eventBody = values[0]
        //   event.appName = values[0]
        //   event.packageName = values[0]
        //   event.decrypted = true
        // }).catch(err => {
        //   event.decrypted = false
        //   event.decryptionError = err
        // }))

      } catch(e) {
        event.packageName = 'unknown (failed to decrypt)'
        event.appName = 'unknown (failed to decrypt)'
        event.decrypted = false
        event.decryptionError = e
      }
    }
  }

  // await Promise.all(allPromises)
  console.log('decryptEvents done')
}

class EventCache {

  constructor() {
    this.cleanUp()
  }

  async getEventsForIdentity(accountId, identity, current, until) {

    // console.log(`${identity}, ${new Date(current).toLocaleString()} (${current}), ${new Date(until).toLocaleString()} (${until})`)

    let item = await getItemFromCache(identity)

    if(item) {
      if(current > item.startTime) {
        // console.log('a')
        try {
          let eventsBefore = await eventsApi.getChunkOfEvents(accountId, identity, current, item.startTime).catch(err => {
            // console.log('c')
            console.log(err)
          })

          await decryptEvents(accountId, identity, eventsBefore)

          // console.log('b ' + (JSON.stringify(eventsBefore)))
          item.events = eventsBefore.concat(item.events)
          item.startTime = Math.max(current - 30 * 1000, until)
        } catch(err) {
          console.log(err)
          return []
        }
      }
      if(until < item.endTime) {
        try {
          let eventsAfter = await eventsApi.getChunkOfEvents(accountId, identity, item.endTime, until)
          await decryptEvents(accountId, identity, eventsAfter)

          item.events = item.events.concat(eventsAfter)
          item.endTime = until
        } catch(err) {
          console.log(err)
          return []
        }
      }

      item.events = Utils.getUniqueObjectArray(item.events, 'key')

      await cacheItem(item)
    } else {
      try {
        let events = await eventsApi.getChunkOfEvents(accountId, identity, current, until)
        await decryptEvents(accountId, identity, events)

        // console.log(events[0])

        events = Utils.getUniqueObjectArray(events, 'key')

        item = new EventCacheItem(
          {
            identity: identity,
            startTime: current,
            endTime: until,
            events: events
          }
        )

        await cacheItem(item)
      } catch(err) {
        console.log(err)
        return []
      }
    }


    let retEvents = []

    for(let event of item.events) {
      if(event.timestamp >= until && event.timestamp <= current) {
        retEvents.push(event)
      }
    }

    return retEvents
  }

  async cleanUp() {
    let keys = await store.keys()
    for(let key of keys) {
      if(isKeyExpired(key)) {
        await store.removeItem(key)
      }
    }
  }

  async clearCache() {
    await store.clear()
  }
}

export default (new EventCache())