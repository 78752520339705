import monthlyPlans from '../components/accounts/plans.2019.05.21.json'
import annualPlans from '../components/accounts/annual.plans.2019.05.21'

/**
 * we pass sensitive information through query string parameters, like email address, idToken, etc.
 * This function cleans it out to ensure it doesn't get sent to our analytics.
 * @param obj
 * @returns {*}
 */
const cleanupValues = (obj) => {
  if(!obj || typeof obj !== 'object') {
    return
  }

  for(const [key, value] of Object.entries(obj)) {
    if(typeof value === 'object') {
      cleanupValues(value)
    } else if(typeof value === 'string') {
      if(value && value.indexOf &&
        ( value.indexOf('/login') !== -1 || //potential login page
          value.indexOf('loginAs') !== -1 || //potential loginAs
          value.indexOf('accountId') !== -1 || //potential accountId
          value.indexOf('idToken') !== -1 || //potential idToken
          value.indexOf('%40') !== -1 || //potential email address (encoded)
          value.indexOf('@') !== -1 || //potential email address
          value.indexOf('access_token') !== -1 || //oauth code
          value.indexOf('scope') !== -1 || //google oauth response referral
          value.indexOf('auth0.com') !== -1 || //generic auth0
          value.indexOf('auth.truple.com') !== -1 || //generic truple's custom auth0 domain')
          value.indexOf('code') !== -1 || //amplify auth return value
          value.indexOf('state') !== -1 || //amplify auth return value
          value.indexOf('cognitoauth.truple.io') !== -1 //cognito auth domain
        )) {


        //slashes cannot exist within query string values, they must be encoded.
        // So this code chops off everything after the last slash
        // or if there isn't a slash, just replaces the entire string.

        let fsli = value.lastIndexOf('/')
        let bsli = value.lastIndexOf('\\')

        let max = Math.max(fsli, bsli)
        if(max !== -1) {
          obj[key] = value.substring(0, max) + '/{removed}'
        } else {
          obj[key] = '{removed}'
        }

        // eslint-disable-next-line no-console
        console.log(`removed ${value} from ${key} (${obj[key]})`)
      }
    }
  }
}

const middleware = function({payload, next}) {
  cleanupValues(payload.obj)
  next(payload)
}

// eslint-disable-next-line no-undef
analytics.addSourceMiddleware(middleware)


class SegmentTracking {

  constructor() {
    this.id = null
  }

  identify(altAccountId) {
    if(this.id !== altAccountId) {
      //analytics = segment
      // eslint-disable-next-line no-undef
      analytics.identify(altAccountId)
      this.id = altAccountId
    }
  }


  track(eventName, properties) {
    // eslint-disable-next-line no-undef
    analytics.track(eventName, properties)
  }

  page(name) {
    // eslint-disable-next-line no-undef
    analytics.page(name, {
      url: location.origin + location.pathname, //strip off search/query params, they sometimes contain login tokens from android app
      search: null, //strip these out
      path: location.pathname
    })
  }
}

const segmentTracking = new SegmentTracking()

function findSubscriptionValueByGoogleSubscriptionId(subscriptionId) {
  const allPlans = annualPlans.concat(monthlyPlans)

  for(let p of allPlans) {
    if(p.value.googlePlaySubscriptionId === subscriptionId) {
      return p
    }
  }
  return null
}

//this gets called from android
window.recordPurchase = (subscriptionId) => {

  const plan = findSubscriptionValueByGoogleSubscriptionId(subscriptionId)

  if(!plan) {
    return "unknown_subscription_id"
  }

  segmentTracking.track("Subscribe", {
    price: plan.value.price,
    category: 'Checkout',
    label: plan.value.googlePlaySubscriptionId,
    value: plan.value.price,
    currency: 'USD'
  })

  segmentTracking.track("AndroidSubscribePassThrough", {
    price: plan.value.price,
    category: 'Checkout',
    label: plan.value.googlePlaySubscriptionId,
    value: plan.value.price,
    currency: 'USD'
  })

  return "success"
}

export default segmentTracking