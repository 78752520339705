//do NOT import from moment here, doing so causes a bug in production "SyntaxError: expected expression, got '<'" but not in dev or anywhere else
// the drift code may import moment as well, and that could be why it was causing us issues.
import moment from "moment-timezone";

const regexPattern = /Signature[a-zA-Z ]*: (\d{8}T\d{6}Z)[a-zA-Z ]*(\d{8}T\d{6}Z) \((\d{8}T\d{6}Z) [-+] 5 min\.\)/

class AWSClockSkewHandler {
  constructor() {
    this.offset = 0
  }

  async handleClockSkew(call) {
    let error = null
    let ret
    for(let c = 0; c < 3; c++) {
      error = null
      try {
        ret = await call().catch(err => {
          console.log('catch block a')
          error = err
        })

        if(!error) {
          return ret
        }
      } catch(e) {
        console.log('try catch')
        console.log(e)
        error = e
      }

      if(this.handleAWSErrorMessage(error)) {
        console.log('handled clock skew!  Offset by ' + this.offset + 'ms')
      } else {
        console.log('didn\'t handle clock skew.... throwing error')
        throw error
      }
    }

    if (error) throw error

    return ret
  }

  handleAWSErrorMessage(err) {
    if(err && err.response && err.response.status === 403) {
      if (err.response.data && err.response.data.message) {

        console.log(err.response.data.message)

        //message should be similar to one of the following:
        // Signature expired: 20200401T023206Z is now earlier than 20200401T032709Z (20200401T033209Z - 5 min.)
        // Signature not yet current: 20200401T052924Z is still later than 20200401T035333Z (20200401T034833Z + 5 min.)
        let message = err.response.data.message
        let match = message.match(regexPattern)
        if(match) {
          let computerTimeWithOffset = moment(match[1]).valueOf()
          let computerTime = computerTimeWithOffset - this.offset

          let awsTime = moment(match[3]).valueOf()

          console.log(awsTime)

          this.offset = awsTime.valueOf() - computerTime
          console.log('this.offset = ' + this.offset + 'ms')
          return true
        }
      }
    }

    return false
  }

  setOffset(val) {
    this.offset = val
  }

  getOffset() {
    return this.offset
  }

  getTime() {
    // console.log('getTime with offset of ' + this.offset)
    return Date.now() + this.offset
  }
}

export default (new AWSClockSkewHandler())