<template>
  <amplify-card>
    <v-card-title class="d-flex justify-center">
      <img v-if="!$store.state.isMobile" src="/static/img/logo.3.white.svg" style="width: 128px; height: 128px;" class="" />
      <div style="width: 100%;" class="text-center">Create your account</div>
    </v-card-title>
    <v-card-text>
      <div>
        <v-form style="background-color: white;">

          <v-alert type="warning" v-if="myAuthData.email && myAuthData.email.endsWith('.decryption.passcode')">
            Please correct your email address!  It doesn't end with '.decryption.passcode'.
          </v-alert>

          <v-text-field
              outlined
              v-model="myAuthData.email"
              @change="authDataChange"
              autofocus
              label="Email Address"
              placeholder=""
              type="email"
              autocomplete="username"
              name="usr"
          />

          <password-text-field
            :auth-data="myAuthData"
            @auth-data-change="authDataChange"
            @on-password-valid-change="passwordValidChange"
            :disabled="loading"
          />

          <div style="width: 100%;" class="d-flex mt-2">
            <div class="d-flex align-center">
              <v-btn text @click="switchToSignIn" class="pl-2 text-none" color="primary" style="cursor: pointer; letter-spacing: normal;">Sign in instead</v-btn>
            </div>
            <v-spacer/>
            <v-btn color="primary" @click="createAccount" :disabled="!isPasswordValid || loading" class="pl-10 pr-10" large
                   :loading="loading"
            >
              Create
            </v-btn>
          </div>
        </v-form>
      </div>

      <div style="width: 100%; margin-top: 32px;" class="d-flex">
        <v-btn text @click="issuesLoggingIn" class="pl-2 text-none caption" color="primary" style="cursor: pointer;" x-small>Issues creating an account?</v-btn>
      </div>

      <div style="width: 100%;" class="hr-sect pb-2">
        or
      </div>

      <google-o-auth-button @on-trigger="googleOAuth" />

      <div class="caption text-center mt-4">
        By signing up you agree to Truple's <br v-if="$store.state.isMobile" /><a href="https://truple.io/termsofuse.html" target="_blank">Terms</a> and <a href="https://truple.io/privacy.html" target="_blank">Privacy Policy</a>.
      </div>
    </v-card-text>
  </amplify-card>
</template>

<script>
import AuthPages from "./AuthPages"
import AmplifyCard from "@/components/amplify/AmplifyCard";
import PasswordTextField from "@/components/amplify/PasswordTextField";
import Auth from "@aws-amplify/auth"
import GoogleOAuthButton from "@/components/amplify/GoogleOAuthButton";
import MiscAuthStorage from "@/components/amplify/MiscAuthStorage";

export default {
  name: "SignUp",
  components: {
    GoogleOAuthButton,
    AmplifyCard,
    PasswordTextField
  },
  props: {
    authData: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      lastSentAt: null,
      verifyCode: '',
      confirmDialog: false,
      loading: false,
      myAuthData: this.authData,
      isPasswordValid: true,
      signInResults: null
    }
  },
  methods: {
    issuesLoggingIn() {
      Auth.federatedSignIn()
    },
    googleOAuth() {
      console.log(this.$route.query.redirect)
      if(this.$route.query.redirect) {
        sessionStorage.setItem('oAuthRedirect', this.$route.query.redirect)
      } else {
        sessionStorage.removeItem('oAuthRedirect')
      }
      Auth.federatedSignIn({
        provider: 'Google'
      })
    },
    async createAccount() {
      this.loading = true
      try {
        let error
        let result = await Auth.signUp({
          username: this.authData.email.trim().toLowerCase(),
          password: this.authData.password,
          attributes: {
            email: this.authData.email
          }
        }).catch(err => {
          error = err
        })
        if(error) throw error
        console.log('success!')

        if(!result.userConfirmed) {
          MiscAuthStorage.setAccountVerificationLastSentAt(Date.now())
          this.switchToConfirmAccount()
        } else {
          this.onAuthentication() //I don't think this can actually happen
        }

      } catch(e) {
        console.log(e)
        this.myAuthData.error = e
        this.authDataChange()
      }
      this.loading = false
    },
    authDataChange() {
      this.$emit('auth-data-change', this.myAuthData)
    },
    switchToConfirmAccount() {
      this.$emit('page', AuthPages.confirmAccount)
    },
    switchToSignIn() {
      this.$emit('page', AuthPages.signIn)
    },
    passwordValidChange(val) {
      console.log(`passwordValidChange ${val}`)
      this.isPasswordValid = val
    },
    onAuthentication() {
      this.$emit('on-authentication')
    }
  }
}
</script>

<style scoped>
  .hr-sect {
    display: flex;
    flex-basis: 100%;
    align-items: center;
    margin: 8px 0px;
  }
  .hr-sect::before,
  .hr-sect::after {
    content: "";
    flex-grow: 1;
    background: rgba(0, 0, 0, 0.6);
    height: 1px;
    font-size: 0px;
    line-height: 0px;
    margin: 0px 8px;
  }

</style>