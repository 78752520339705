var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.currentPageValue === _vm.pages.signIn
        ? _c("sign-in", {
            attrs: { "auth-data": _vm.authData },
            on: {
              page: _vm.page,
              "auth-data-change": _vm.authDataChange,
              "on-authentication": _vm.onAuthenticated
            }
          })
        : _vm.currentPageValue === _vm.pages.signUp
        ? _c("sign-up", {
            attrs: { "auth-data": _vm.authData },
            on: {
              page: _vm.page,
              "auth-data-change": _vm.authDataChange,
              "on-authentication": _vm.onAuthenticated
            }
          })
        : _vm.currentPageValue === _vm.pages.confirmAccount
        ? _c("verify-account", {
            attrs: { "auth-data": _vm.authData },
            on: {
              page: _vm.page,
              "auth-data-change": _vm.authDataChange,
              "on-authentication": _vm.onAuthenticated
            }
          })
        : _vm.currentPageValue === _vm.pages.forgotPassword
        ? _c("forgot-password", {
            attrs: { "auth-data": _vm.authData },
            on: { page: _vm.page, "auth-data-change": _vm.authDataChange }
          })
        : _c("div", [
            _vm._v(
              "\n    " + _vm._s("unknown page " + _vm.currentPage) + "\n  "
            )
          ]),
      _c(
        "v-snackbar",
        {
          attrs: {
            timeout: 10000,
            top: true,
            absolute: true,
            right: !_vm.$store.state.isMobile
          },
          scopedSlots: _vm._u([
            {
              key: "action",
              fn: function(ref) {
                var attrs = ref.attrs
                return [
                  _c(
                    "v-btn",
                    _vm._b(
                      {
                        attrs: { icon: "" },
                        on: {
                          click: function($event) {
                            _vm.snackbar = false
                          }
                        }
                      },
                      "v-btn",
                      attrs,
                      false
                    ),
                    [_c("v-icon", [_vm._v("close")])],
                    1
                  )
                ]
              }
            }
          ]),
          model: {
            value: _vm.snackbar,
            callback: function($$v) {
              _vm.snackbar = $$v
            },
            expression: "snackbar"
          }
        },
        [_vm._v("\n    " + _vm._s(_vm.snackbarMessage) + "\n    ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }