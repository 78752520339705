class Utils {

  sleep(ms) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve()
      }, ms)
    })
  }

  reverseString(str) {
    if(!str) {
      return str
    }
    return str.split('').reverse().join('')
  }

  getDurationString (diff) {
    let seconds = Math.floor(diff / 1000) % 60
    let minutes = Math.floor(diff / (1000 * 60)) % 60
    let hours = Math.floor(diff / (1000 * 60 * 60))

    let time = seconds + " seconds"

    if(minutes > 0) {
      time = minutes + " minutes, " + time
    }

    if(hours > 0) {
      time = hours + " hours, " + time
    }

    return time
  }

  getUrl(string) {
    try {
      if(!string) {
        return false;
      }
      if(string.indexOf(' ') !== -1 || string.indexOf('.') === -1) {
        return false;
      }
      // eslint-disable-next-line no-control-regex
      string = string.toLowerCase().trim().replace(/[^\x00-\x7F]+/g, '')

      if(string.indexOf('://') === -1) {
        string = 'https://' + string
      }
      return new URL(string)
    } catch (e) {
      return null
    }
  }

  isValidUrl(string) {
    try {
      if(!string) {
        return false;
      }
      if(string.indexOf(' ') !== -1 || string.indexOf('.') === -1) {
        return false;
      }
      // eslint-disable-next-line no-control-regex
      string = string.toLowerCase().trim().replace(/[^\x00-\x7F]+/g, '')

      //.replace(/[^\x00-\x7F]+/g, '') removes hidden characters that Samsung Internet browser introduces.  Don't remove it.

      if(string.indexOf('://') === -1) {
        string = 'https://' + string
      }
      new URL(string);
      return true;
    } catch (e) {
      return false;
    }
  }

  getUniqueObjectArray(arr, uniqueKey) {
    const keySet = new Set()
    return arr.filter((item) => {
      if (!keySet.has(item[uniqueKey])) {
        keySet.add(item[uniqueKey])
        return true
      } else {
        return false
      }
    })
  }

  shuffle(array) {
    let currentIndex = array.length;
    let randomIndex;

    while (currentIndex !== 0) {

      randomIndex = Math.floor(Math.random() * currentIndex)
      currentIndex--

      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex], array[currentIndex]]
    }

    return array;
  }

  replaceNonASCII(input) {
    // Normalize the string to decompose characters (NFD form)
    const normalized = input.normalize('NFD');

    // Replace characters with accents and diacritics
    const asciiOnly = normalized.replace(/[\u0300-\u036f]/g, "");

    // Replace any remaining non-ASCII characters with underscores
    const result = asciiOnly.replace(/[^ -~]/g, '_');  // ASCII range is 32-126

    return result
  }
}

export default (new Utils())
