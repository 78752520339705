<template>
  <div class="container" @click="clicked">
    <img src="/static/img/btn_google_dark_normal_ios.svg" class="logo"/>
    <div class="txt noselect">
      <span style="font-size: 0.875rem;">Sign in with Google</span>
    </div>
  </div>
</template>
<script>
export default {
  name: 'sign-in-with-google',
  methods: {
    clicked() {
      this.$emit('clicked')
    }
  }
}
</script>
<style scoped>

.container {
  padding: 0px;
  display: inline-flex;
  background-color: #4285f4;
  border-radius: 2px;
  cursor: pointer;
  max-width: 208px;
}

.container:hover {
  box-shadow: inset 0 0 0 10em rgba(255, 255, 255, 0.15);
}

.logo {
  height: 38px;
  width: 38px;
  margin-right: 24px;
}

.txt {
  color: white;
  font-weight: 500;
  display: flex;
  align-items: center;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                            supported by Chrome, Edge, Opera and Firefox */
}
</style>