import 'babel-polyfill'


//these imports replace the stylesheet ref's in index.html
// <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900">
//   <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Material+Icons">
import './static/css/google.materialicons.css'
import './static/css/google.roboto.css'

import Vue from 'vue'
import store from './store'
import vuetify from './plugins/vuetify';

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

const options = {
  confirmButtonColor: '#00796B',
  cancelButtonColor: '#757575',
};

Vue.use(VueSweetalert2, options)

import AsyncComputed from 'vue-async-computed'
Vue.use(AsyncComputed)


// eslint-disable-next-line no-console
console.log(`version=${process.env.VUE_APP_VERSION}`)

// import './plugins/vuetify'
import App from './App.vue'

import Auth from "@aws-amplify/auth";
import trupledevawsconfig from "./amplify/truple/dev/aws-exports";
import trupleprodawsconfig from "./amplify/truple/prod/aws-exports";
import badevawsconfig from "./amplify/bestaccountability/dev/aws-exports";
import baprodawsconfig from "./amplify/bestaccountability/prod/aws-exports";

let cfg
if(process.env.VUE_APP_PRODUCT === 'bestaccountability') {
  cfg = process.env.VUE_APP_AWS_ENV === 'prod' ? baprodawsconfig : badevawsconfig
} else {
  cfg = process.env.VUE_APP_AWS_ENV === 'prod' ? trupleprodawsconfig : trupledevawsconfig
}

if(process.env.VUE_APP_ENV_AMPLIFY_CLIENT_ID_OVERRIDE) {
  // eslint-disable-next-line no-console
  console.log('process.env.VUE_APP_ENV_AMPLIFY_CLIENT_ID_OVERRIDE')
  // cfg.aws_user_pools_web_client_id = process.env.VUE_APP_ENV_AMPLIFY_CLIENT_ID_OVERRIDE
  // cfg.Auth.userPoolWebClientId = process.env.VUE_APP_ENV_AMPLIFY_CLIENT_ID_OVERRIDE
  // cfg.Auth.oauth.clientId = process.env.VUE_APP_ENV_AMPLIFY_CLIENT_ID_OVERRIDE
  // cfg.Auth.oauth.redirectSignIn = 'http://localhost:8080/'
  // cfg.Auth.oauth.redirectSignOut = 'http://localhost:8080/'
}

if(process.env.VUE_APP_LOCAL === "true") {
  // eslint-disable-next-line no-console
  console.log('process.env.VUE_APP_LOCAL ' + process.env.VUE_APP_LOCAL)
  cfg.Auth.oauth.redirectSignIn = 'http://localhost:8080/'
  cfg.Auth.oauth.redirectSignOut = 'http://localhost:8080/'
}

if(process.env.VUE_APP_AWS_ENV === 'prod' && localStorage.getItem('debug') !== 'true') {
  // eslint-disable-next-line no-console
  console.log = () => {}
}

// if(process.env.VUE_APP_AWS_ENV === 'dev') {
//   window.LOG_LEVEL = 'DEBUG'
// }

Auth.configure(cfg)

import Chartkick from 'vue-chartkick'
import ChartDataLabels from 'chartjs-plugin-datalabels';
import Chart from 'chart.js'
Chart.plugins.register([ChartDataLabels]) //this is done automatically, but including it here so we don't remove ChartDataLabels becuase it looks like it's never used
Chart.defaults.scale.gridLines.display = false
Chart.defaults.global.plugins.datalabels.anchor = 'end'
Chart.defaults.global.plugins.datalabels.clamp = true
Chart.defaults.global.plugins.datalabels.align = 'end'
Vue.use(Chartkick.use(Chart))

// eslint-disable-next-line no-unused-vars
import { extend } from 'vee-validate';
// eslint-disable-next-line no-unused-vars
import { required } from 'vee-validate/dist/rules';

import VueClipboard from 'vue-clipboard2'
VueClipboard.config.autoSetContainer = true
Vue.use(VueClipboard)

import router from './lib/router'

import auth from './lib/auth'
import api from './lib/api'


Vue.use({
  install(Vue) {
    Vue.prototype.auth = auth
    Vue.prototype.api = api
  }
})

Vue.config.productionTip = false

new Vue({
  vuetify,
  render: h => h(App),
  router,
  store
}).$mount('#app')
