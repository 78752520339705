// see https://docs.amplify.aws/lib/client-configuration/configuring-amplify-categories/q/platform/js#scoped-configuration

export default {
  aws_project_region: 'us-west-2',
  aws_cognito_region: 'us-west-2',
  aws_cognito_identity_pool_id: 'us-west-2:c62c8823-81e2-4457-b7ca-5d4510c2c315',
  aws_user_pools_id: 'us-west-2_ovZQPfuXH',
  aws_user_pools_web_client_id: '768q50564c5uklvv0fa30qtrfe',
  aws_mandatory_sign_in: 'enabled',
  Auth: {
    region: 'us-west-2',
    identityPoolId: 'us-west-2:c62c8823-81e2-4457-b7ca-5d4510c2c315',
    userPoolId: 'us-west-2_ovZQPfuXH',
    userPoolWebClientId: '768q50564c5uklvv0fa30qtrfe',
    mandatorySignIn: true,
    // this causes an error https://github.com/aws-amplify/amplify-js/issues/2702
    // cookieStorage: {
    //   domain: 'http://localhost:8008',//'http://localhost:8080',
    // //   path: '/',
    // //   expires: 365,
    // //   sameSite: 'strict',
    // //   secure: false
    // },
    authenticationFlowType: 'USER_PASSWORD_AUTH', //switch to USER_SRP_AUTH once we're done migrating email/password combos off auth0.  By 6/1/2021 you can make the change.
    oauth: {
      domain: 'dev-netcountable-v2.auth.us-west-2.amazoncognito.com',
      scope: ['email', 'openid', 'profile'],
      // redirectSignIn: 'http://localhost:8080/',
      // redirectSignOut: 'http://localhost:8080/',
      redirectSignIn: 'https://dev.app.truple.io/',
      redirectSignOut: 'https://dev.app.truple.io/',
      clientId: '768q50564c5uklvv0fa30qtrfe',
      responseType: 'code' //'code' = refresh token, 'token' = no refresh token
    }
  }
}