var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c(
        "v-layout",
        { attrs: { "justify-center": "", "align-center": "" } },
        [
          !_vm.dialog
            ? _c("v-progress-circular", {
                attrs: { indeterminate: "", color: "accent" }
              })
            : _vm._e(),
          _c(
            "v-dialog",
            {
              attrs: { "hide-overlay": "", persistent: "", width: "300" },
              model: {
                value: _vm.dialog,
                callback: function($$v) {
                  _vm.dialog = $$v
                },
                expression: "dialog"
              }
            },
            [
              _c(
                "v-card",
                { attrs: { color: "primary", dark: "" } },
                [
                  _c(
                    "v-card-text",
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.message ? _vm.message : "Loading...") +
                          "\n          "
                      ),
                      _c("v-progress-linear", {
                        staticClass: "mb-0 mt-1",
                        attrs: { indeterminate: "", color: "white" }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }