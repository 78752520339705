class MiscAuthStorage {
  setAccountVerificationLastSentAt(time) {
    time = time || Date.now()
    localStorage.setItem('last-sent-confirm-account-email', time.toString())
  }
  getAccountVerificaitonLastSentAt() {
    let lastSentAt = localStorage.getItem('last-sent-confirm-account-email')
    if(lastSentAt) {
      lastSentAt = parseInt(lastSentAt)
    }
    return lastSentAt
  }
}

export default (new MiscAuthStorage())