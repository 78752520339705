// see https://docs.amplify.aws/lib/client-configuration/configuring-amplify-categories/q/platform/js#scoped-configuration

export default {
  aws_project_region: 'us-west-2',
  aws_cognito_region: 'us-west-2',
  aws_user_pools_id: 'us-west-2_IzUhqgqbg',
  aws_user_pools_web_client_id: '16ssbnobbnvjet4kj1s45sqk1g',
  aws_cognito_identity_pool_id: 'us-west-2:1b90ebde-e3a7-4c80-afd3-6d3a57101b04',
  aws_mandatory_sign_in: 'enabled',
  Auth: {
    region: 'us-west-2',
    identityPoolId: 'us-west-2:1b90ebde-e3a7-4c80-afd3-6d3a57101b04',
    userPoolId: 'us-west-2_IzUhqgqbg',
    userPoolWebClientId: '16ssbnobbnvjet4kj1s45sqk1g',
    mandatorySignIn: true,
    // cookieStorage: {
    //   domain: '.truple.io',
    //   path: '/',
    //   expires: 365,
    //   sameSite: 'strict',
    //   secure: true
    // },
    authenticationFlowType: 'USER_PASSWORD_AUTH', //switch to USER_SRP_AUTH once we're done migrating email/password combos off auth0.  By 6/1/2021 you can make the change.
    oauth: {
      domain: 'cognitoauth.truple.io',
      scope: ['email', 'openid', 'profile'],
      redirectSignIn: 'https://app.truple.io/',
      redirectSignOut: 'https://app.truple.io/',
      clientId: '16ssbnobbnvjet4kj1s45sqk1g',
      responseType: 'code' //switch to code if you want a refresh token
    }
  }
}