var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      staticStyle: { "z-index": "1001 !important" },
      attrs: {
        persistent: "",
        "max-width": "600px",
        fullscreen: _vm.$store.state.isMobile
      },
      model: {
        value: _vm.showDialog,
        callback: function($$v) {
          _vm.showDialog = $$v
        },
        expression: "showDialog"
      }
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", { staticClass: "primary" }, [
            _c("span", { staticClass: "white--text title" }, [
              _vm._v("Review & Agree")
            ])
          ]),
          _c(
            "v-card-text",
            { staticClass: "grow" },
            [
              _c(
                "v-container",
                {
                  staticClass: "pa-0 ma-0 pt-5",
                  attrs: { fluid: "", "fill-height": "" }
                },
                [
                  _vm.shouldAskForMarketingAndFeedback
                    ? _c(
                        "v-layout",
                        { attrs: { row: "", wrap: "" } },
                        [
                          _c(
                            "v-col",
                            { staticClass: "mb-2 pa-2", attrs: { cols: 12 } },
                            [
                              _c("div", { staticClass: "title" }, [
                                _vm._v("Terms of Use")
                              ]),
                              _c("div", {
                                staticClass: "caption",
                                staticStyle: { float: "right" }
                              }),
                              _c(
                                "a",
                                {
                                  attrs: {
                                    href: "https://truple.io/termsofuse.html",
                                    target: "_blank"
                                  }
                                },
                                [_vm._v("Click here to view")]
                              )
                            ]
                          ),
                          _c(
                            "v-col",
                            { staticClass: "mb-2 pa-2", attrs: { cols: 12 } },
                            [
                              _c("div", { staticClass: "title" }, [
                                _vm._v("Privacy Policy")
                              ]),
                              _c("div", {
                                staticClass: "caption",
                                staticStyle: { float: "right" }
                              }),
                              _c(
                                "a",
                                {
                                  attrs: {
                                    href: "https://truple.io/privacy.html",
                                    target: "_blank"
                                  }
                                },
                                [_vm._v("Click here to view")]
                              )
                            ]
                          ),
                          _vm.shouldAskForMarketingAndFeedback
                            ? [
                                _c(
                                  "v-col",
                                  {
                                    staticClass: "pa-2",
                                    attrs: { cols: 12, sm6: "" }
                                  },
                                  [
                                    _c("b", [
                                      _vm._v(
                                        "Would you like to be notified of new features, offerings, and discounts?"
                                      )
                                    ]),
                                    _c(
                                      "v-radio-group",
                                      {
                                        staticClass: "pa-0 ma-0",
                                        attrs: {
                                          column: false,
                                          "error-messages":
                                            _vm.marketing === null
                                              ? ["required"]
                                              : []
                                        },
                                        model: {
                                          value: _vm.marketing,
                                          callback: function($$v) {
                                            _vm.marketing = $$v
                                          },
                                          expression: "marketing"
                                        }
                                      },
                                      [
                                        _c("v-radio", {
                                          attrs: { label: "yes", value: "yes" }
                                        }),
                                        _c("v-radio", {
                                          staticClass: "ml-2",
                                          attrs: { label: "no", value: "no" }
                                        })
                                      ],
                                      1
                                    ),
                                    _vm.marketing !== null &&
                                    _vm.$store.state.account &&
                                    _vm.$store.state.account.account &&
                                    _vm.$store.state.account.account
                                      .createdTimestamp &&
                                    _vm.$store.state.account.account
                                      .createdTimestamp >
                                      Date.now() - 7 * 24 * 60 * 60 * 1000 &&
                                    _vm.$store.state.inApp
                                      ? _c(
                                          "v-alert",
                                          {
                                            attrs: {
                                              dense: "",
                                              type:
                                                _vm.marketing === "yes"
                                                  ? "success"
                                                  : "error"
                                            }
                                          },
                                          [
                                            _vm.marketing === "yes"
                                              ? [
                                                  _vm._v(
                                                    "\n                    Check your email inbox for a $3 discount code!\n                  "
                                                  )
                                                ]
                                              : [
                                                  _vm._v(
                                                    "\n                    You won't receive an immediate $3 discount code.\n                  "
                                                  )
                                                ]
                                          ],
                                          2
                                        )
                                      : _vm._e()
                                  ],
                                  1
                                )
                              ]
                            : _vm._e()
                        ],
                        2
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-card-actions",
            [
              _c(
                "v-btn",
                {
                  attrs: {
                    text: "",
                    color: "secondary",
                    disabled: _vm.accepting,
                    outlined: ""
                  },
                  on: {
                    click: function($event) {
                      return _vm.decline()
                    }
                  }
                },
                [_vm._v("Decline")]
              ),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: {
                    color: "primary",
                    disabled:
                      _vm.accepting ||
                      _vm.marketing === null || _vm.feedback === null
                  },
                  on: {
                    click: function($event) {
                      return _vm.accept()
                    }
                  }
                },
                [
                  _c("span", { staticClass: "pr-1" }, [_vm._v("Agree")]),
                  _vm.accepting
                    ? _c("v-progress-circular", {
                        staticClass: "ml-2",
                        attrs: { indeterminate: true, value: 0, size: "24" }
                      })
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }