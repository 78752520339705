import AccountApi from '../../aws/account/AccountApi'
import EThreeExtender from "./EThreeExtender";
import E2EECardCache from "./E2EECardCache";

const accountCardFilter = (card) => {
  // console.log(JSON.stringify(card))
  if(!card.isOutdated) {
    for(let signature of card.signatures) {
      if(signature.extraFields && signature.extraFields.accountCard === "true") {
        // console.log('true')
        return true
      }
    }
  }
  // console.log('false')
  return false
}

//const isWebAssemblySupported = false; //e3kit appears to have a webassembly bug in v2.3.2, undo this
const isWebAssemblySupported = (() => {
  try {
    if (typeof WebAssembly === "object"
      && typeof WebAssembly.instantiate === "function") {
      const module = new WebAssembly.Module(Uint8Array.of(0x0, 0x61, 0x73, 0x6d, 0x01, 0x00, 0x00, 0x00));
      if (module instanceof WebAssembly.Module)
        return new WebAssembly.Instance(module) instanceof WebAssembly.Instance;
    }
  } catch (e) {
    console.log(e)
  }
  return false;
})();

class E2EEHelper {

  constructor(forAccountId) {
    this.accountId = forAccountId
    this.virgilAccountIdentityId = null
    this.eThree = null
    this.initPromise = this.init(forAccountId)
    this.e2eeEnabled = null
    this.hasLocalKey = null
    this.bypassPrivateKeyMissing = false
    this.jwt = null

    this.maxTimestampWithSucccessfulDecryption = 0
    this.lastRefreshedCardToVerifyCard = 0
  }

  _tokenCallback(forAccountId) {
    return async () => {
      let response = await AccountApi.getJwtToken(forAccountId)

      if(!response || !response.data) {
        console.log(response)
        alert('An issue occured.  We\'ll reload the page.  If this repeats, contact support@truple.io')
        location.reload()
      }

      this.virgilAccountIdentityId = response.data.altAccountId
      this.jwt = response.data.jwt
      return response.data.jwt
    }
  }

  async revokeCardsForDeletedDevices(set) {
    await this.initPromise
    return await this.eThree.revokeCardsForDeletedDevices(set)
  }

  async getWebWorkerE2EEConstructionObject() {
    // let privateKey = await this.eThree.virgilCrypto.exportPrivateKey()
    return {
      jwt: this.jwt,
      virgilAccountIdentityId: this.virgilAccountIdentityId,
      accountId: this.accountId
    }
  }

  async init(forAccountId) {
    console.log('init started')
    let e3Kit = null
    if(isWebAssemblySupported) {
      try {
        e3Kit = await import('@virgilsecurity/e3kit-browser')
        // console.log('using web assembly')
      } catch(e) {
        console.log(e)
      }
    }
    if(!e3Kit) {
      console.log('failed to load wasm, loading asmjs')
      e3Kit = await import('@virgilsecurity/e3kit-browser/dist/browser.asmjs.es')
      console.log('using asmjs')
    }

    let originalEThree = await e3Kit.EThree.initialize(this._tokenCallback(forAccountId))
    this.eThree = EThreeExtender.extend(originalEThree)
    // console.log('eThree loaded')

    this.e2eeEnabled = await this.hasE2eEnabled()
    this.hasLocalKey = await this._hasPrivateKeyThatIsValid()
      // this.eThree.hasLocalPrivateKey()
    // console.log('init finished')
  }

  _getCachedAccountCardId() {
    return localStorage.getItem(`${this.accountId}|${this.virgilAccountIdentityId}|account.cardId`)
  }
  _setCachedAccountCardId(value) {
    localStorage.setItem(`${this.accountId}|${this.virgilAccountIdentityId}|account.cardId`, value)
  }

  async _hasPrivateKeyThatIsValid() {
    console.log('__hasPrivateKeyThatIsValid')
    let hasPrivateKey = await this.eThree.hasLocalPrivateKey()
    console.log('__hasPrivateKeyThatIsValid done')

    if(hasPrivateKey) {
      console.log('hasPrivateKey')
      let storedCardId = this._getCachedAccountCardId()
      console.log('_getCachedAccountCardId done')
      try {
        let cloudCardId = await this._getAccountCardId()
        console.log('hasPrivateKey done')
        if(storedCardId === cloudCardId) {
          return true
        }
      } catch(e) {

        //account must have subscribed then cancelled, with the private key still present on the browser storage but no longer valid.  Lets clean it up.
        console.log(JSON.stringify(e, null, 2))
        await this.eThree.cleanup().catch(err => {
          console.log('error cleanup')
          console.log(err)
        })
      }
    }

    return false
  }

  async decryptImage(identityId, blob, imageTimestamp) {
    if(blob === null) {
      return null
    }
    if(typeof blob === 'undefined') {
      return blob
    }

    await this.initPromise
    let cardToVerify = await E2EECardCache.getSendersCard(this, identityId)

    let decryptFunction = async () => {
      let error
      console.log('decryptFunction')
      console.log('cardToVerify' + JSON.stringify(cardToVerify))
      let ret = await this.eThree.authDecryptFile(blob, cardToVerify).catch(err => {
      // let ret = await this.eThree.authDecrypt(blob, cardToVerify).catch(err => {
        error = err
        console.log(error)
      })
      if(error) throw error
      return ret
    }

    let decryptedBlob

    try {
      let error
      decryptedBlob = await decryptFunction().catch(err => {
        error = err
      })
      if(error) throw error

      if(this.maxTimestampWithSucccessfulDecryption < imageTimestamp) {
        this.maxTimestampWithSucccessfulDecryption = imageTimestamp
      }

    } catch(e) {
      console.log(e)

      if(this.maxTimestampWithSucccessfulDecryption <= imageTimestamp && Date.now() - this.lastRefreshedCardToVerifyCard > 60 * 1000) {
        this.lastRefreshedCardToVerifyCard = Date.now()
        console.log('invalidateCard')
        //could be a stale card, reload...  Unlikely? though.
        E2EECardCache.invalidateCard(identityId)
        cardToVerify = await E2EECardCache.getSendersCard(this, identityId)
        let error
        decryptedBlob = await decryptFunction().catch(err => {
          error = err
        })
        if(error) throw error
      } else {
        throw e
      }
    }

    return decryptedBlob
  }

  async decryptString(identityId, str, timestamp) {
    if(str === null) {
      return null
    }
    if(typeof str === 'undefined') {
      return str
    }

    await this.initPromise
    let cardToVerify = await E2EECardCache.getSendersCard(this, identityId)

    let decryptFunction = async () => {
      let error
      let ret = await this.eThree.authDecrypt(str, cardToVerify).catch(err => {
        error = err
        console.log(error)
      })
      if(error) throw error
      return ret
    }

    let decryptedString

    try {
      let error
      decryptedString = await decryptFunction().catch(err => {
        error = err
      })
      if(error) throw error

      if(this.maxTimestampWithSucccessfulDecryption < timestamp) {
        this.maxTimestampWithSucccessfulDecryption = timestamp
      }

    } catch(e) {
      console.log(e)
      if(this.maxTimestampWithSucccessfulDecryption <= timestamp && Date.now() - this.lastRefreshedCardToVerifyCard > 60 * 1000) {
        this.lastRefreshedCardToVerifyCard = Date.now()
        //could be a stale card, reload...  Unlikely? though.
        E2EECardCache.invalidateCard(identityId)
        cardToVerify = await E2EECardCache.getSendersCard(this, identityId)
        let error
        decryptedString = await decryptFunction().catch(err => {
          error = err
        })
        if(error) throw error
      } else {
        throw e
      }
    }

    return decryptedString
  }

  async unregisterAccountAndAllCards() {
    await this.initPromise
    console.log('unregisterUser')
    try {
      await this.eThree.unregisterWithMultipleCards(() => { return true; }).catch(err => {
        console.log('error unregisterWithMultipleCards')
        console.log(err)
      })
    } catch(e) {
      console.log('unregisterWithMultipleCards')
      console.log(e)
    }

    try {
      await this.eThree.resetPrivateKeyBackup().catch(err => {
        console.log('error resetPrivateKeyBackup')
        console.log(err)
      })
    } catch(e) {
      console.log('resetPrivateKeyBackup')
      console.log(e)
    }

    try {
      await this.eThree.cleanup().catch(err => {
        console.log('error cleanup')
        console.log(err)
      })
    } catch(e) {
      console.log('cleanup')
      console.log(e)
    }
  }

  async unregisterUser() {
    await this.initPromise
    console.log('unregisterUser')

    // await this.eThree.unregisterWithMultipleCards(() => { return true; }).catch(err => {
    //   console.log('error unregisterWithMultipleCards')
    //   console.log(err)
    // })

    // await this.eThree.unregisterWithMultipleCards(accountCardFilter).catch(err => {
    //   console.log('error unregisterWithMultipleCards')
    //   console.log(err)
    // })
    await this.eThree.resetPrivateKeyBackup().catch(err => {
      console.log('error resetPrivateKeyBackup')
      console.log(err)
    })
    await this.eThree.cleanup().catch(err => {
      console.log('error cleanup')
      console.log(err)
    })
  }

  async registerUser(password) {
    await this.initPromise

    try {
      await this.unregisterAccountAndAllCards()
    } catch(e) {
      console.log(`error unregistering right before registering`)
      console.log(e)
    }

    await this.eThree.registerWithMultipleCards(
      undefined,
      accountCardFilter,
      {
        accountCard: "true"
      }
    )
    let accountCardId = await this._getAccountCardId()
    this._setCachedAccountCardId(accountCardId)

    this.e2eeEnabled = true
    this.hasLocalKey = await this._hasPrivateKeyThatIsValid()
    console.log('registerUser complete, starting backup')
    try {
      await this.backup(password)
    } catch(e) {
      console.log(e)
      await this.unregisterAccountAndAllCards()
      alert('An error occurred registering your e2ee passcode.  Please try again later.  If this repeatedly occurs, contact support@truple.io')
      location.reload()
    }
    console.log('backup successful')
  }

  // getPasswordFromLocalStorage() {
  //   return localStorage.getItem(`virgil-password.${this.virgilAccountIdentityId}`)
  // }

  async backup(password) {
    await this.initPromise
    await this.eThree.backupPrivateKey(password)
  }

  async changeBackupPassword(oldPassword, newPassword) {
    await this.initPromise
    return await this.eThree.changePassword(oldPassword, newPassword)
  }

  async _getAccountCardId() {
    let error
    let result = await this.eThree.findUsersWithCardFilter(
      this.virgilAccountIdentityId,
      accountCardFilter
    ).catch(err => {
      error = err
    })
    if(error) throw error
    return result.id
  }

  async downloadPrivateKey(password) {
    await this.initPromise
    let accountCardId = await this._getAccountCardId()
    const hasLocalPrivateKey = await this.eThree.hasLocalPrivateKey();
    if (hasLocalPrivateKey) {
      await this.eThree.cleanup()
    }

    await this.eThree.restorePrivateKey(password);
    this.hasLocalKey = await this.eThree.hasLocalPrivateKey()

    let accountCardIdAfterRestoring = await this._getAccountCardId()

    if(accountCardId !== accountCardIdAfterRestoring) {
      throw new Error('Encryption key changed while attempting to restore the key, please try again.')
    }

    this._setCachedAccountCardId(accountCardId)
  }

  // async unregisterUser() {
  //   if(!this.forAccountId) {
  //     //should be done on backend when cancelling subscription
  //     //can't unregister unless it's for your own account
  //   }
  // }


  async rotatePrivateKey() {
    await this.initPromise
    const hasLocalPrivateKey = await this.eThree.hasLocalPrivateKey()

    if(hasLocalPrivateKey) {
      // await this.eThree.resetPrivateKeyBackup()
      // await this.eThree.cleanup()
      console.log('resetPrivateKeyBackup')
      await this.eThree.resetPrivateKeyBackup().catch(err => {
        console.log('resetPrivateKeyBackup error')
        console.log(err)
      })
      await this.eThree.cleanup()
    }

    await this.eThree.rotatePrivateKeyWithMultipleCards(
      accountCardFilter,
      {
        accountCard: "true"
      }
    )
  }

  async getAllCards() {
    let cards = []
    await this.eThree.findUsersWithCardFilter(
      this.virgilAccountIdentityId,
      (card) => {
        cards.push(card)
        return false
      }
    ).catch(() => {

    })
    return cards
  }

  async hasE2eEnabled() {
    try {
      let error
      console.log('looking for ' + this.virgilAccountIdentityId)
      let result = await this.eThree.findUsersWithCardFilter(
        this.virgilAccountIdentityId,
        accountCardFilter
      ).catch(err => {
        console.log('throwing!')
        error = err
      })
      if(error) throw error

      // let count = 0;
      // for

      return true
    } catch(e) {
      console.log('hasE2eEnabled error')
      console.log(e.name)
      if(e.name && e.name !== 'UsersNotFoundError') {
        console.log(JSON.stringify(e, null, 2))
      }
      return false
    }
  }

  async debug() {
    await this.initPromise
    let result = await this.eThree.resetPrivateKeyBackup()
    console.log(result)
    result = await this.eThree.unregister(true)
    await this.eThree.cleanUp()

    console.log('success')
    // let result = eThree.rotatePrivateKey()
    // let result = eThree.findUsers(this.virgilAccountIdentityId)
  }

  async removePrivateKeyFromLocalStorage() {
    await this.initPromise
    //if on public device.
    await this.eThree.cleanUp()
  }

  async deletePrivateKey() {

    await this.eThree.resetPrivateKeyBackup().catch(err => {
      console.log('resetPrivateKeyBackup error')
      console.log(err)
    })

    const hasLocalPrivateKey = await this.eThree.hasLocalPrivateKey()

    if(hasLocalPrivateKey) {
      // await this.eThree.resetPrivateKeyBackup()
      // await this.eThree.cleanup()
      console.log('resetPrivateKeyBackup')
      await this.eThree.cleanup()
    }
  }
}

export default E2EEHelper