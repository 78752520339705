const utils = {
    assertDefined: function(object, name) {
        if (object === undefined) {
            throw new Error(`${name} must be defined`);
        } else {
            return object;
        }
    },
    assertParametersDefined: function(params, keys, ignore) {
        if (keys === undefined) {
            return;
        }
        if (keys.length > 0 && params === undefined) {
            params = {};
        }
        for (let i = 0; i < keys.length; i++) {
            if (!utils.contains(ignore, keys[i])) {
                utils.assertDefined(params[keys[i]], keys[i]);
            }
        }
    },
    parseParametersToObject: function(params, keys) {
        if (params === undefined) {
            return {};
        }
        let object = { };
        for (let i = 0; i < keys.length; i++) {
            object[keys[i]] = params[keys[i]];
        }
        return object;
    },
    contains: function(a, obj) {
        if (a === undefined) {
            return false;
        }
        let i = a.length;
        while (i--) {
            if (a[i] === obj) {
                return true;
            }
        }
        return false;
    },
    copy: function(obj) {
        if (null === obj || 'object' !== typeof obj) return obj;
        if (Buffer.isBuffer(obj)) return Buffer.from(obj);
        let copy = obj.constructor();
        let attr = null;
        for (attr in obj) {
            if (obj.hasOwnProperty(attr)) copy[attr] = obj[attr];
        }
        return copy;
    },
    mergeInto: function(baseObj, additionalProps) {
        if (null === baseObj || 'object' !== typeof baseObj) return baseObj;
        let merged = baseObj.constructor();
        let attr = null;
        for (attr in baseObj) {
            if (baseObj.hasOwnProperty(attr)) merged[attr] = baseObj[attr];
        }
        if (null == additionalProps || 'object' != typeof additionalProps) return baseObj;
        for (attr in additionalProps) {
            if (additionalProps.hasOwnProperty(attr)) merged[attr] = additionalProps[attr];
        }
        return merged;
    },

};

export default utils;