import StoreConstants from "@/StoreConstants";
import store from '@/store'

class ReferrerHelper {
  run(href, referrer) {
    console.log('TIMING: referrerhelper.run')
    if(href) {
      store.commit(StoreConstants.commits.setHref, href)
    }

    if(referrer) {
      store.commit(StoreConstants.commits.setReferrer, referrer)
    }
    else if(document.referrer) {
      store.commit(StoreConstants.commits.setReferrer, document.referrer)
    }
  }
}

export default  (new ReferrerHelper())