var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: {
        "max-width": "600px",
        scrollable: "",
        persistent: "",
        fullscreen: _vm.$vuetify.breakpoint.smAndDown
      },
      on: { close: _vm.close },
      model: {
        value: _vm.showDialog,
        callback: function($$v) {
          _vm.showDialog = $$v
        },
        expression: "showDialog"
      }
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            [
              _vm.currentQuestion === 0
                ? _c("span", [_vm._v("Feedback Survey")])
                : _vm._e(),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { icon: "", disabled: _vm.submitting },
                  on: { click: _vm.close }
                },
                [_c("v-icon", [_vm._v("close")])],
                1
              )
            ],
            1
          ),
          _c(
            "v-card-text",
            [
              _vm.currentQuestion === 0
                ? _c(
                    "survey-question",
                    {
                      attrs: {
                        "back-button-text": null,
                        "next-disabled": !_vm.answers.bestAccountabilityApp
                      },
                      on: {
                        "on-next": function($event) {
                          _vm.currentQuestion = 1
                        }
                      }
                    },
                    [
                      _c("yes-no", {
                        attrs: {
                          question:
                            "Is " +
                            _vm.appName +
                            " the best parental monitoring/accountability app you've used?"
                        },
                        model: {
                          value: _vm.answers.bestAccountabilityApp,
                          callback: function($$v) {
                            _vm.$set(_vm.answers, "bestAccountabilityApp", $$v)
                          },
                          expression: "answers.bestAccountabilityApp"
                        }
                      })
                    ],
                    1
                  )
                : _vm.currentQuestion === 1 &&
                  _vm.answers.bestAccountabilityApp === "yes"
                ? _c(
                    "survey-question",
                    {
                      attrs: {
                        "next-disabled": false,
                        "next-button-text": "Submit",
                        "next-loading": _vm.submitting
                      },
                      on: {
                        "on-back": function($event) {
                          _vm.currentQuestion = 0
                        },
                        "on-next": _vm.submit
                      }
                    },
                    [
                      _c(
                        "div",
                        [
                          _c("p", { staticClass: "text-h5" }, [
                            _vm._v(
                              "Please help our ratings reflect that " +
                                _vm._s(_vm.appName) +
                                " is the best."
                            )
                          ]),
                          _c(
                            "p",
                            [
                              _c("span", { staticClass: "font-weight-bold" }, [
                                _vm._v(
                                  "Why should you care about " +
                                    _vm._s(_vm.appName) +
                                    "'s rating?"
                                )
                              ]),
                              _c("br"),
                              _vm._v("\n              Better ratings "),
                              _c("v-icon", [_vm._v("arrow_right_alt")]),
                              _vm._v(" Easier time marketing "),
                              _c("v-icon", [_vm._v("arrow_right_alt")]),
                              _vm._v(
                                " " +
                                  _vm._s(_vm.appName) +
                                  " has more resources to dedicate to improving the product."
                              )
                            ],
                            1
                          ),
                          _c("p", [
                            _c("b", [_vm._v("A 5 star rating helps.")]),
                            _vm._v(
                              "  We only get 10 or so reviews / month, so "
                            ),
                            _c(
                              "span",
                              { staticClass: "text-decoration-underline" },
                              [_vm._v("every one counts")]
                            ),
                            _vm._v("."),
                            _c("br"),
                            _c("b", [
                              _vm._v("A 1-4 star rating "),
                              _c(
                                "span",
                                { staticClass: "text-decoration-underline" },
                                [_vm._v("harms")]
                              ),
                              _vm._v(" " + _vm._s(_vm.appName) + ".")
                            ]),
                            _c(
                              "a",
                              {
                                staticClass: "font-italic ml-3 caption",
                                on: {
                                  click: function($event) {
                                    _vm.showLearnWhy = !_vm.showLearnWhy
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.showLearnWhy
                                      ? "(close)"
                                      : "(learn why 4 star ratings do harm)"
                                  )
                                )
                              ]
                            )
                          ]),
                          _c("v-expand-transition", [
                            _vm.showLearnWhy
                              ? _c("p", [
                                  _vm._v(
                                    "\n                App ratings are used to position " +
                                      _vm._s(_vm.appName) +
                                      " either above or below other apps in search results.\n                Most people only install the top 1-2 apps, so having a good position is very important.\n                A 5 star rating should be given when the app is better than the alternatives.  4 star or lower ratings will position other apps above " +
                                      _vm._s(_vm.appName) +
                                      ".\n                A 5 star rating does not require a perfect app, though we'll work towards that continually.\n              "
                                  )
                                ])
                              : _vm._e()
                          ]),
                          _c(
                            "v-btn",
                            {
                              staticClass: "mb-1 text-none",
                              attrs: {
                                href:
                                  "https://play.google.com/store/apps/details?id=com.camhart.netcountable",
                                target: "_blank",
                                color: "primary",
                                disabled: _vm.submitting,
                                block: ""
                              },
                              on: {
                                click: function($event) {
                                  _vm.answers.clickedLeaveRating = true
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n              Leave Rating on Google Play\n            "
                              )
                            ]
                          ),
                          _c("p", { staticClass: "caption font-italic" }, [
                            _vm._v(
                              "Leaving a rating is optional, but we greatly appreciate it.  If you don't want to leave one, just click Submit."
                            )
                          ]),
                          _c(
                            "v-btn",
                            {
                              attrs: { text: "" },
                              on: {
                                click: function($event) {
                                  _vm.showAlreadyLeftOne = true
                                }
                              }
                            },
                            [_vm._v("I've already left a review")]
                          ),
                          _c(
                            "v-dialog",
                            {
                              attrs: { width: "400px" },
                              model: {
                                value: _vm.showAlreadyLeftOne,
                                callback: function($$v) {
                                  _vm.showAlreadyLeftOne = $$v
                                },
                                expression: "showAlreadyLeftOne"
                              }
                            },
                            [
                              _c(
                                "v-card",
                                [
                                  _c("v-card-title", [
                                    _vm._v(
                                      "\n                  Thank you!\n                "
                                    )
                                  ]),
                                  _c("v-card-text", [
                                    _vm._v(
                                      "\n                  If it's been a while, you can consider updating your review.  Updating reviews help due to Google's new algorithm that weighs recent reviews more heavily.\n                  "
                                    ),
                                    _c("br"),
                                    _c("br"),
                                    _vm._v(
                                      "\n                  Otherwise just submit the survey without updating your review and know that we're grateful for your support.\n                "
                                    )
                                  ]),
                                  _c(
                                    "v-card-actions",
                                    [
                                      _c("v-spacer"),
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: { color: "primary" },
                                          on: {
                                            click: function($event) {
                                              _vm.showAlreadyLeftOne = false
                                            }
                                          }
                                        },
                                        [_vm._v("Close")]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]
                  )
                : _vm.currentQuestion === 1 &&
                  _vm.answers.bestAccountabilityApp === "no"
                ? _c(
                    "survey-question",
                    {
                      attrs: {
                        "next-disabled":
                          !_vm.answers.improveDescription_freetext ||
                          _vm.submitting,
                        "next-button-text": "Submit",
                        "next-loading": _vm.submitting
                      },
                      on: {
                        "on-back": function($event) {
                          _vm.currentQuestion = 0
                        },
                        "on-next": _vm.submit
                      }
                    },
                    [
                      _c("long-text", {
                        attrs: {
                          question:
                            "How can we improve " +
                            _vm.appName +
                            " so it is the best?",
                          autofocus: true
                        },
                        model: {
                          value: _vm.answers.improveDescription_freetext,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.answers,
                              "improveDescription_freetext",
                              $$v
                            )
                          },
                          expression: "answers.improveDescription_freetext"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.currentQuestion === 10
                ? _c(
                    "survey-question",
                    {
                      attrs: {
                        "back-button-text": false,
                        "next-button-text": "Close",
                        "next-disabled": _vm.submitting,
                        "next-loading": _vm.submitting
                      },
                      on: { "on-next": _vm.close }
                    },
                    [
                      _c("div", { staticClass: "mt-6" }, [
                        _c("div", { staticClass: "title" }, [
                          _vm._v("Thanks for the feedback!")
                        ]),
                        _c("div", [
                          _vm._v(
                            "\n              We plan on asking every 3 months for feedback.  We don't want these to get too annoying, but it's important for us to hear from you so we understand how well we're serving you.  Thank you for taking the time.\n            "
                          )
                        ])
                      ])
                    ]
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }