<template>
  <amplify-card>
    <v-card-title>
      Verify account
    </v-card-title>
    <v-card-text>
      <div class="mb-6">{{`We just sent a code to ${myAuthData.email}.  Please enter the code to verify your account.`}}</div>
      <v-text-field
          outlined
          v-model="verifyCode"
          label="Verification Code"
          placeholder=""
      />

      <div>
        <span>Can't find the email? </span> <v-btn text @click="resendSignup" x-small :disabled="loading">Resend Code</v-btn>
      </div>
      <div v-if="lastSentAt">
        {{`Last email sent at ${new Date(lastSentAt).toLocaleString()}`}}
      </div>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="primary" @click="verify" :disabled="!verifyCode || loading" :loading="loading">Verify</v-btn>
    </v-card-actions>
  </amplify-card>
</template>

<script>
import AmplifyCard from "@/components/amplify/AmplifyCard";
import Auth from "@aws-amplify/auth";
import MiscAuthStorage from "@/components/amplify/MiscAuthStorage";
export default {
name: "VerifyAccount",
  components: {AmplifyCard},
  props: {
    authData: {
      type: Object,
      required: true
    },
    sendOnMount: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      confirmDialog: false,
      lastSentAt: null,
      loading: false,
      verifyCode: '',
      myAuthData: this.authData
    }
  },
  mounted() {
    this.lastSentAt = MiscAuthStorage.getAccountVerificaitonLastSentAt()
    if(Date.now() - this.lastSentAt > 60000) {
      this.resendSignup()
    }
  },
  methods: {
    async resendSignup() {
      this.loading = true
      try {
        let error
        let result = await Auth.resendSignUp(this.authData.email.trim().toLowerCase()).catch(err => {
          error = err
        })
        if(error) throw error
        console.log(result)
        if(result) {
          this.lastSentAt = Date.now()
          MiscAuthStorage.setAccountVerificationLastSentAt(this.lastSentAt)
        }
      } catch(e) {
        console.log(e)
        this.myAuthData.error = e
        this.authDataChange()
      }
      this.loading = false
    },
    async verify() {
      this.loading = true
      try {
        let error
        let result = await Auth.confirmSignUp(this.authData.email.trim().toLowerCase(), this.verifyCode.trim()).catch(err => {
          error = err
        })
        if(error) throw error
        console.log('success!')

        this.confirmDialog = false

        await Auth.signIn(this.authData.email, this.authData.password)

        this.onAuthentication()

      } catch(e) {
        console.log(e)
        this.myAuthData.error = e
        this.authDataChange()
      }
      this.loading = false
    },
    onAuthentication() {
      this.$emit('on-authentication')
    },
    authDataChange() {
      this.$emit('auth-data-change', this.myAuthData)
    },
  }
}
</script>

<style scoped>

</style>