var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "amplify-card",
    [
      _c("v-card-title", [_vm._v("\n    Verify account\n  ")]),
      _c(
        "v-card-text",
        [
          _c("div", { staticClass: "mb-6" }, [
            _vm._v(
              _vm._s(
                "We just sent a code to " +
                  _vm.myAuthData.email +
                  ".  Please enter the code to verify your account."
              )
            )
          ]),
          _c("v-text-field", {
            attrs: {
              outlined: "",
              label: "Verification Code",
              placeholder: ""
            },
            model: {
              value: _vm.verifyCode,
              callback: function($$v) {
                _vm.verifyCode = $$v
              },
              expression: "verifyCode"
            }
          }),
          _c(
            "div",
            [
              _c("span", [_vm._v("Can't find the email? ")]),
              _c(
                "v-btn",
                {
                  attrs: { text: "", "x-small": "", disabled: _vm.loading },
                  on: { click: _vm.resendSignup }
                },
                [_vm._v("Resend Code")]
              )
            ],
            1
          ),
          _vm.lastSentAt
            ? _c("div", [
                _vm._v(
                  "\n      " +
                    _vm._s(
                      "Last email sent at " +
                        new Date(_vm.lastSentAt).toLocaleString()
                    ) +
                    "\n    "
                )
              ])
            : _vm._e()
        ],
        1
      ),
      _c(
        "v-card-actions",
        [
          _c("v-spacer"),
          _c(
            "v-btn",
            {
              attrs: {
                color: "primary",
                disabled: !_vm.verifyCode || _vm.loading,
                loading: _vm.loading
              },
              on: { click: _vm.verify }
            },
            [_vm._v("Verify")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }