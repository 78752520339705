var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticStyle: {
        width: "100%",
        display: "flex",
        "justify-content": "center",
        "align-items": "center"
      }
    },
    [_c("sign-in-with-google", { on: { clicked: _vm.clicked } })],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }