/*
 * Copyright 2010-2016 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License").
 * You may not use this file except in compliance with the License.
 * A copy of the License is located at
 *
 *  http://aws.amazon.com/apache2.0
 *
 * or in the "license" file accompanying this file. This file is distributed
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either
 * express or implied. See the License for the specific language governing
 * permissions and limitations under the License.
 */

import utils from '../apiGatewayCore/utils';
import { apiGatewayClientFactory } from '../apiGatewayCore/apiGatewayClient.js'
import uritemplate from "../apiGatewayCore/url-template"

var apigClientFactory = {};
apigClientFactory.newClient = function (config, invokeUrl) {
    var apigClient = { };
    if(config === undefined) {
        config = {
            accessKey: '',
            secretKey: '',
            sessionToken: '',
            region: '',
            apiKey: undefined,
            defaultContentType: 'application/json',
            defaultAcceptType: 'application/json'
        };
    }
    if(config.accessKey === undefined) {
        config.accessKey = '';
    }
    if(config.secretKey === undefined) {
        config.secretKey = '';
    }
    if(config.apiKey === undefined) {
        config.apiKey = '';
    }
    if(config.sessionToken === undefined) {
        config.sessionToken = '';
    }
    if(config.region === undefined) {
        config.region = 'us-east-1';
    }
    //If defaultContentType is not defined then default to application/json
    if(config.defaultContentType === undefined) {
        config.defaultContentType = 'application/json';
    }
    //If defaultAcceptType is not defined then default to application/json
    if(config.defaultAcceptType === undefined) {
        config.defaultAcceptType = 'application/json';
    }

    
    // extract endpoint and path from url
    // eslint-disable-next-line no-useless-escape
    var endpoint = /(^https?:\/\/[^\/]+)/g.exec(invokeUrl)[1];
    var pathComponent = invokeUrl.substring(endpoint.length);

    var sigV4ClientConfig = {
        accessKey: config.accessKey,
        secretKey: config.secretKey,
        sessionToken: config.sessionToken,
        serviceName: 'execute-api',
        region: config.region,
        endpoint: endpoint,
        defaultContentType: config.defaultContentType,
        defaultAcceptType: config.defaultAcceptType
    };

    var authType = 'NONE';
    if (sigV4ClientConfig.accessKey !== undefined && sigV4ClientConfig.accessKey !== '' && sigV4ClientConfig.secretKey !== undefined && sigV4ClientConfig.secretKey !== '') {
        authType = 'AWS_IAM';
    }

    var simpleHttpClientConfig = {
        endpoint: endpoint,
        defaultContentType: config.defaultContentType,
        defaultAcceptType: config.defaultAcceptType
    };

    var apiGatewayClient = apiGatewayClientFactory.newClient(simpleHttpClientConfig, sigV4ClientConfig);


    apigClient.accountsGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }

        utils.assertParametersDefined(params, ['deviceUUID'], ['body']);

        var accountsGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/accounts').expand(utils.parseParametersToObject(params, [])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, ['deviceUUID']),
            body: body
        };


        return apiGatewayClient.makeRequest(accountsGetRequest, authType, additionalParams, config.apiKey);
    };


    apigClient.accountsPut = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }

        utils.assertParametersDefined(params, ['body'], ['body']);

        var accountsPutRequest = {
            verb: 'put'.toUpperCase(),
            path: pathComponent + uritemplate('/accounts').expand(utils.parseParametersToObject(params, [])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };


        return apiGatewayClient.makeRequest(accountsPutRequest, authType, additionalParams, config.apiKey);
    };


    apigClient.accountsOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }

        utils.assertParametersDefined(params, [], ['body']);

        var accountsOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/accounts').expand(utils.parseParametersToObject(params, [])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };


        return apiGatewayClient.makeRequest(accountsOptionsRequest, authType, additionalParams, config.apiKey);
    };


    apigClient.accountsAcceptTermsPost = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }

        utils.assertParametersDefined(params, ['feedback', 'marketing', 'deviceUUID'], ['body']);

        var accountsAcceptTermsPostRequest = {
            verb: 'post'.toUpperCase(),
            path: pathComponent + uritemplate('/accounts/acceptTerms').expand(utils.parseParametersToObject(params, [])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, ['feedback', 'marketing', 'deviceUUID']),
            body: body
        };


        return apiGatewayClient.makeRequest(accountsAcceptTermsPostRequest, authType, additionalParams, config.apiKey);
    };


    apigClient.accountsAcceptTermsOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }

        utils.assertParametersDefined(params, [], ['body']);

        var accountsAcceptTermsOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/accounts/acceptTerms').expand(utils.parseParametersToObject(params, [])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };


        return apiGatewayClient.makeRequest(accountsAcceptTermsOptionsRequest, authType, additionalParams, config.apiKey);
    };


    apigClient.accountsDevicesGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }

        utils.assertParametersDefined(params, ['deviceUUID'], ['body']);

        var accountsDevicesGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/accounts/devices').expand(utils.parseParametersToObject(params, [])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, ['deviceUUID']),
            body: body
        };


        return apiGatewayClient.makeRequest(accountsDevicesGetRequest, authType, additionalParams, config.apiKey);
    };


    apigClient.accountsDevicesPut = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }

        utils.assertParametersDefined(params, ['body', 'singleDevice', 'deviceUUID'], ['body']);

        var accountsDevicesPutRequest = {
            verb: 'put'.toUpperCase(),
            path: pathComponent + uritemplate('/accounts/devices').expand(utils.parseParametersToObject(params, [])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, ['singleDevice', 'deviceUUID']),
            body: body
        };


        return apiGatewayClient.makeRequest(accountsDevicesPutRequest, authType, additionalParams, config.apiKey);
    };


    apigClient.accountsDevicesOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }

        utils.assertParametersDefined(params, [], ['body']);

        var accountsDevicesOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/accounts/devices').expand(utils.parseParametersToObject(params, [])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };


        return apiGatewayClient.makeRequest(accountsDevicesOptionsRequest, authType, additionalParams, config.apiKey);
    };


    apigClient.accountsInviteCodePut = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }

        utils.assertParametersDefined(params, ['body', 'deviceUUID', 'os'], ['body']);

        var accountsInviteCodePutRequest = {
            verb: 'put'.toUpperCase(),
            path: pathComponent + uritemplate('/accounts/inviteCode').expand(utils.parseParametersToObject(params, [])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, ['deviceUUID', 'os']),
            body: body
        };


        return apiGatewayClient.makeRequest(accountsInviteCodePutRequest, authType, additionalParams, config.apiKey);
    };


    apigClient.accountsInviteCodePost = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }

        utils.assertParametersDefined(params, ['body', 'deviceUUID'], ['body']);

        var accountsInviteCodePostRequest = {
            verb: 'post'.toUpperCase(),
            path: pathComponent + uritemplate('/accounts/inviteCode').expand(utils.parseParametersToObject(params, [])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, ['deviceUUID']),
            body: body
        };


        return apiGatewayClient.makeRequest(accountsInviteCodePostRequest, authType, additionalParams, config.apiKey);
    };


    apigClient.accountsInviteCodeOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }

        utils.assertParametersDefined(params, [], ['body']);

        var accountsInviteCodeOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/accounts/inviteCode').expand(utils.parseParametersToObject(params, [])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };


        return apiGatewayClient.makeRequest(accountsInviteCodeOptionsRequest, authType, additionalParams, config.apiKey);
    };


    apigClient.couponPost = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }

        utils.assertParametersDefined(params, ['body', 'deviceUUID'], ['body']);

        var couponPostRequest = {
            verb: 'post'.toUpperCase(),
            path: pathComponent + uritemplate('/coupon').expand(utils.parseParametersToObject(params, [])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, ['deviceUUID']),
            body: body
        };


        return apiGatewayClient.makeRequest(couponPostRequest, authType, additionalParams, config.apiKey);
    };


    apigClient.couponOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }

        utils.assertParametersDefined(params, [], ['body']);

        var couponOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/coupon').expand(utils.parseParametersToObject(params, [])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };


        return apiGatewayClient.makeRequest(couponOptionsRequest, authType, additionalParams, config.apiKey);
    };

    apigClient.dailyEmailImagesGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }

        utils.assertParametersDefined(params, ['frequency', 'accountId', 'recipient', 'version'], ['body']);

        var dailyEmailImagesGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/dailyEmail/images').expand(utils.parseParametersToObject(params, [])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, ['frequency', 'accountId', 'recipient', 'version']),
            body: body
        };

        return apiGatewayClient.makeRequest(dailyEmailImagesGetRequest, authType, additionalParams, config.apiKey);
    };


    apigClient.dailyEmailImagesOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }

        utils.assertParametersDefined(params, [], ['body']);

        var dailyEmailImagesOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/dailyEmail/images').expand(utils.parseParametersToObject(params, [])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };


        return apiGatewayClient.makeRequest(dailyEmailImagesOptionsRequest, authType, additionalParams, config.apiKey);
    };


    apigClient.dailyEmailUnsubscribeGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }

        utils.assertParametersDefined(params, [], ['body']);

        var dailyEmailUnsubscribeGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/dailyEmail/unsubscribe').expand(utils.parseParametersToObject(params, [])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };


        return apiGatewayClient.makeRequest(dailyEmailUnsubscribeGetRequest, authType, additionalParams, config.apiKey);
    };


    apigClient.dailyEmailUnsubscribeOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }

        utils.assertParametersDefined(params, [], ['body']);

        var dailyEmailUnsubscribeOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/dailyEmail/unsubscribe').expand(utils.parseParametersToObject(params, [])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };


        return apiGatewayClient.makeRequest(dailyEmailUnsubscribeOptionsRequest, authType, additionalParams, config.apiKey);
    };


    apigClient.emailGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }

        utils.assertParametersDefined(params, ['deviceUUID'], ['body']);

        var emailGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/email').expand(utils.parseParametersToObject(params, [])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, ['deviceUUID']),
            body: body
        };


        return apiGatewayClient.makeRequest(emailGetRequest, authType, additionalParams, config.apiKey);
    };


    apigClient.emailOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }

        utils.assertParametersDefined(params, [], ['body']);

        var emailOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/email').expand(utils.parseParametersToObject(params, [])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };


        return apiGatewayClient.makeRequest(emailOptionsRequest, authType, additionalParams, config.apiKey);
    };


    apigClient.emailAddPost = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }

        utils.assertParametersDefined(params, ['body', 'deviceUUID'], ['body']);

        var emailAddPostRequest = {
            verb: 'post'.toUpperCase(),
            path: pathComponent + uritemplate('/email/add').expand(utils.parseParametersToObject(params, [])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, ['deviceUUID']),
            body: body
        };


        return apiGatewayClient.makeRequest(emailAddPostRequest, authType, additionalParams, config.apiKey);
    };


    apigClient.emailAddOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }

        utils.assertParametersDefined(params, [], ['body']);

        var emailAddOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/email/add').expand(utils.parseParametersToObject(params, [])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };


        return apiGatewayClient.makeRequest(emailAddOptionsRequest, authType, additionalParams, config.apiKey);
    };


    apigClient.emailRemovePost = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }

        utils.assertParametersDefined(params, ['body', 'deviceUUID'], ['body']);

        var emailRemovePostRequest = {
            verb: 'post'.toUpperCase(),
            path: pathComponent + uritemplate('/email/remove').expand(utils.parseParametersToObject(params, [])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, ['deviceUUID']),
            body: body
        };


        return apiGatewayClient.makeRequest(emailRemovePostRequest, authType, additionalParams, config.apiKey);
    };


    apigClient.emailRemoveOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }

        utils.assertParametersDefined(params, [], ['body']);

        var emailRemoveOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/email/remove').expand(utils.parseParametersToObject(params, [])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };


        return apiGatewayClient.makeRequest(emailRemoveOptionsRequest, authType, additionalParams, config.apiKey);
    };


    apigClient.imagesCreatePresignedImageUploadUrlPost = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }

        utils.assertParametersDefined(params, ['body', 'deviceUUID', 'version'], ['body']);

        var imagesCreatePresignedImageUploadUrlPostRequest = {
            verb: 'post'.toUpperCase(),
            path: pathComponent + uritemplate('/images/createPresignedImageUploadUrl').expand(utils.parseParametersToObject(params, [])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, ['deviceUUID', 'version']),
            body: body
        };


        return apiGatewayClient.makeRequest(imagesCreatePresignedImageUploadUrlPostRequest, authType, additionalParams, config.apiKey);
    };


    apigClient.imagesDeletePost = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }

        utils.assertParametersDefined(params, ['body', 'deviceUUID'], ['body']);

        var imagesDeletePostRequest = {
            verb: 'post'.toUpperCase(),
            path: pathComponent + uritemplate('/images/delete').expand(utils.parseParametersToObject(params, [])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, ['deviceUUID']),
            body: body
        };


        return apiGatewayClient.makeRequest(imagesDeletePostRequest, authType, additionalParams, config.apiKey);
    };


    apigClient.imagesDeleteOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }

        utils.assertParametersDefined(params, [], ['body']);

        var imagesDeleteOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/images/delete').expand(utils.parseParametersToObject(params, [])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };


        return apiGatewayClient.makeRequest(imagesDeleteOptionsRequest, authType, additionalParams, config.apiKey);
    };


    apigClient.imagesGetImageRecordsPost = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }

        utils.assertParametersDefined(params, ['body', 'deviceUUID', 'forAccountId', 'screenshotsForIdentityId'], ['body']);

        var imagesGetImageRecordsPostRequest = {
            verb: 'post'.toUpperCase(),
            path: pathComponent + uritemplate('/images/getImageRecords').expand(utils.parseParametersToObject(params, [])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, ['deviceUUID', 'forAccountId', 'screenshotsForIdentityId']),
            body: body
        };


        return apiGatewayClient.makeRequest(imagesGetImageRecordsPostRequest, authType, additionalParams, config.apiKey);
    };


    apigClient.imagesGetImageRecordsOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }

        utils.assertParametersDefined(params, [], ['body']);

        var imagesGetImageRecordsOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/images/getImageRecords').expand(utils.parseParametersToObject(params, [])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };


        return apiGatewayClient.makeRequest(imagesGetImageRecordsOptionsRequest, authType, additionalParams, config.apiKey);
    };


    apigClient.subscribePost = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }

        utils.assertParametersDefined(params, ['body', 'deviceUUID'], ['body']);

        var subscribePostRequest = {
            verb: 'post'.toUpperCase(),
            path: pathComponent + uritemplate('/subscribe').expand(utils.parseParametersToObject(params, [])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, ['deviceUUID']),
            body: body
        };


        return apiGatewayClient.makeRequest(subscribePostRequest, authType, additionalParams, config.apiKey);
    };


    apigClient.subscribeOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }

        utils.assertParametersDefined(params, [], ['body']);

        var subscribeOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/subscribe').expand(utils.parseParametersToObject(params, [])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };


        return apiGatewayClient.makeRequest(subscribeOptionsRequest, authType, additionalParams, config.apiKey);
    };


    apigClient.subscribeCancelPost = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }

        utils.assertParametersDefined(params, ['deviceUUID'], ['body']);

        var subscribeCancelPostRequest = {
            verb: 'post'.toUpperCase(),
            path: pathComponent + uritemplate('/subscribe/cancel').expand(utils.parseParametersToObject(params, [])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, ['deviceUUID']),
            body: body
        };


        return apiGatewayClient.makeRequest(subscribeCancelPostRequest, authType, additionalParams, config.apiKey);
    };


    apigClient.subscribeCancelOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }

        utils.assertParametersDefined(params, [], ['body']);

        var subscribeCancelOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/subscribe/cancel').expand(utils.parseParametersToObject(params, [])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };


        return apiGatewayClient.makeRequest(subscribeCancelOptionsRequest, authType, additionalParams, config.apiKey);
    };


    apigClient.subscriptionChargePost = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }

        utils.assertParametersDefined(params, [], ['body']);

        var subscriptionChargePostRequest = {
            verb: 'post'.toUpperCase(),
            path: pathComponent + uritemplate('/subscription/charge').expand(utils.parseParametersToObject(params, [])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };


        return apiGatewayClient.makeRequest(subscriptionChargePostRequest, authType, additionalParams, config.apiKey);
    };

    return apigClient;
};

export default apigClientFactory