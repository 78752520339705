/* eslint-disable no-console */

let requestPromises = {}

const deviceCardFilter = (identityId) => {
  return (card) => {
    console.log(card)
    if(!card.isOutdated) {
      for (let signature of card.signatures) {
        if (signature.extraFields && signature.extraFields.identityId === identityId) {
          console.log('deviceCardFilter true')
          return true
        }
      }
    }
    console.log('deviceCardFilter false')
    return false
  }
}


class E2EECardCache {

  constructor() {
    this.memoryCache = {}
  }

  invalidateCard(identityId) {
    delete this.memoryCache[identityId]
    if(localStorage) {
      localStorage.removeItem(`senderCard.${identityId}`)
    }
  }

  _loadCardFromApi(e2eeHelper, accountId, cardFilter) {
    return new Promise((resolve) => {
      try {
        e2eeHelper.eThree.findUsersWithCardFilter(
          e2eeHelper.virgilAccountIdentityId,
          cardFilter
        ).then(result => {
          resolve(result)
        }).catch((e) => {
          console.log(e)
          resolve(false)
        })
      } catch(e) {
        console.log(e)
        resolve(false)
      }
    })
  }

  async getSendersCard(e2eeHelper, identityId) {
    console.log(`getSendersCard looking under ${e2eeHelper.virgilAccountIdentityId} for ${identityId}`)

    let p = requestPromises[identityId]
    if(p) {
      await p
    }

    if(this.memoryCache[identityId] === undefined) {
      try {
        let error

        let prom = this._loadCardFromApi(e2eeHelper, e2eeHelper.virgilAccountIdentityId, deviceCardFilter(identityId))
        requestPromises[identityId] = prom

        let result = await prom
        if(error) throw error

        this.memoryCache[identityId] = result
      } catch(e) {
        this.memoryCache[identityId] = null
      }
    }

    return this.memoryCache[identityId]
  }

}

export default (new E2EECardCache())