<template>
  <v-card max-width="400" width="400">
    <slot></slot>
  </v-card>
</template>

<script>
export default {
  name: "AmplifyCard"
}
</script>

<style scoped>

</style>