var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "amplify-card",
    [
      _c("v-card-title", { staticClass: "d-flex justify-center" }, [
        !_vm.$store.state.isMobile
          ? _c("img", {
              staticStyle: { width: "128px", height: "128px" },
              attrs: { src: "/static/img/logo.3.white.svg" }
            })
          : _vm._e(),
        _c(
          "div",
          { staticClass: "text-center", staticStyle: { width: "100%" } },
          [_vm._v("Create your account")]
        )
      ]),
      _c(
        "v-card-text",
        [
          _c(
            "div",
            [
              _c(
                "v-form",
                { staticStyle: { "background-color": "white" } },
                [
                  _vm.myAuthData.email &&
                  _vm.myAuthData.email.endsWith(".decryption.passcode")
                    ? _c("v-alert", { attrs: { type: "warning" } }, [
                        _vm._v(
                          "\n          Please correct your email address!  It doesn't end with '.decryption.passcode'.\n        "
                        )
                      ])
                    : _vm._e(),
                  _c("v-text-field", {
                    attrs: {
                      outlined: "",
                      autofocus: "",
                      label: "Email Address",
                      placeholder: "",
                      type: "email",
                      autocomplete: "username",
                      name: "usr"
                    },
                    on: { change: _vm.authDataChange },
                    model: {
                      value: _vm.myAuthData.email,
                      callback: function($$v) {
                        _vm.$set(_vm.myAuthData, "email", $$v)
                      },
                      expression: "myAuthData.email"
                    }
                  }),
                  _c("password-text-field", {
                    attrs: {
                      "auth-data": _vm.myAuthData,
                      disabled: _vm.loading
                    },
                    on: {
                      "auth-data-change": _vm.authDataChange,
                      "on-password-valid-change": _vm.passwordValidChange
                    }
                  }),
                  _c(
                    "div",
                    {
                      staticClass: "d-flex mt-2",
                      staticStyle: { width: "100%" }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "d-flex align-center" },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "pl-2 text-none",
                              staticStyle: {
                                cursor: "pointer",
                                "letter-spacing": "normal"
                              },
                              attrs: { text: "", color: "primary" },
                              on: { click: _vm.switchToSignIn }
                            },
                            [_vm._v("Sign in instead")]
                          )
                        ],
                        1
                      ),
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          staticClass: "pl-10 pr-10",
                          attrs: {
                            color: "primary",
                            disabled: !_vm.isPasswordValid || _vm.loading,
                            large: "",
                            loading: _vm.loading
                          },
                          on: { click: _vm.createAccount }
                        },
                        [_vm._v("\n            Create\n          ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "d-flex",
              staticStyle: { width: "100%", "margin-top": "32px" }
            },
            [
              _c(
                "v-btn",
                {
                  staticClass: "pl-2 text-none caption",
                  staticStyle: { cursor: "pointer" },
                  attrs: { text: "", color: "primary", "x-small": "" },
                  on: { click: _vm.issuesLoggingIn }
                },
                [_vm._v("Issues creating an account?")]
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "hr-sect pb-2", staticStyle: { width: "100%" } },
            [_vm._v("\n      or\n    ")]
          ),
          _c("google-o-auth-button", { on: { "on-trigger": _vm.googleOAuth } }),
          _c("div", { staticClass: "caption text-center mt-4" }, [
            _vm._v("\n      By signing up you agree to Truple's "),
            _vm.$store.state.isMobile ? _c("br") : _vm._e(),
            _c(
              "a",
              {
                attrs: {
                  href: "https://truple.io/termsofuse.html",
                  target: "_blank"
                }
              },
              [_vm._v("Terms")]
            ),
            _vm._v(" and "),
            _c(
              "a",
              {
                attrs: {
                  href: "https://truple.io/privacy.html",
                  target: "_blank"
                }
              },
              [_vm._v("Privacy Policy")]
            ),
            _vm._v(".\n    ")
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }