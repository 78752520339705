<template>
  <v-app id="truple">
    <v-app-bar color="primary" dark fixed app v-if="!$store.state.inApp">
      <v-expand-transition v-if="isLoggedIn()">
        <v-app-bar-nav-icon @click.stop="drawer = (!drawer && isLoggedIn())"></v-app-bar-nav-icon>
      </v-expand-transition>
      <v-toolbar-title style="display: flex;">
        <img src="/static/img/logo.3.white.svg" style="width: 32px; height: 32px;" />
        <span class="ml-2">{{ appName }}</span>
      </v-toolbar-title>
    </v-app-bar>
    <v-navigation-drawer
            v-if="!$store.state.inApp"
            v-model="drawer"
            fixed
            clipped
            temporary
            app
    >
      <v-list dense>
        <v-list-item @click="$router.push('login')" v-if="!isLoggedIn()">
          <v-list-item-action>
            <v-icon>account_circle</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Sign Up</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="$router.push('login')" v-if="!isLoggedIn()">
          <v-list-item-action>
            <v-icon>transit_enterexit</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Login</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="$router.push('home')"  v-if="isLoggedIn()" :disabled="$route.path.toLowerCase().indexOf('home') !== -1 || $route.path.toLowerCase().endsWith('.io/#/') || $route.path.toLowerCase().endsWith('.io/')">
          <v-list-item-action>
            <v-icon>home</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Home</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="$router.push('allscreenshots')"  v-if="isLoggedIn()" :disabled="$route.path.toLowerCase().indexOf('allscreenshots') !== -1 || $route.path.toLowerCase().endsWith('.io/#/allscreenshots')">
          <v-list-item-action>
            <v-icon>photo</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>All Screenshots</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="$router.push('filter')"  v-if="isLoggedIn()" :disabled="$route.path.toLowerCase().indexOf('filter') !== -1">
          <v-list-item-action>
            <v-icon>security</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Web Filter</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="$router.push('devices')"  v-if="isLoggedIn()" :disabled="$route.path.toLowerCase().indexOf('devices') !== -1">
          <v-list-item-action>
            <v-icon>smartphone</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Devices</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="$router.push('emails')"  v-if="isLoggedIn()" :disabled="$route.path.toLowerCase().indexOf('emails') !== -1">
          <v-list-item-action>
            <v-icon>email</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Reporting & Alerts</v-list-item-title>
            <v-list-item-subtitle>Report Recipients</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="$router.push('account')" v-if="isLoggedIn()" :disabled="$route.path.toLowerCase().indexOf('account') !== -1">
          <v-list-item-action>
            <v-icon>account_box</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Account</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item @click="$router.push('setup')" v-if="isLoggedIn()" :disabled="$route.path.toLowerCase().indexOf('setup') !== -1">
          <v-list-item-action>
            <v-icon>done_all</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Setup Guide</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

<!--        <v-list-item @click="$router.push('updates')" v-if="isLoggedIn()" :disabled="$route.path.toLowerCase().indexOf('updates') !== -1">-->
<!--          <v-list-item-action>-->
<!--            <v-icon>new_releases</v-icon>-->
<!--          </v-list-item-action>-->
<!--          <v-list-item-content>-->
<!--            <v-list-item-title>Update Log</v-list-item-title>-->
<!--&lt;!&ndash;            <v-list-item-subtitle>See whats new</v-list-item-subtitle>&ndash;&gt;-->
<!--          </v-list-item-content>-->
<!--        </v-list-item>-->
        <v-list-item :href="`https://${supportDomain}`" target="_blank">
          <v-list-item-action>
            <v-icon>help</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Support</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item href="https://stories.truple.io" target="_blank">
          <v-list-item-action>
            <v-icon>comment</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Community</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item @click="$router.push('rating')"  v-if="isLoggedIn()" :disabled="$route.path.toLowerCase().indexOf('rating') !== -1 || $route.path.toLowerCase().endsWith('.io/#/rating')">
          <v-list-item-action>
            <v-icon>construction</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Analyzer</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
<!--        <v-list-item @click="$router.push('discounts')" v-if="isLoggedIn()" :disabled="$route.path.toLowerCase().indexOf('discounts') !== -1">-->
<!--          <v-list-item-action>-->
<!--            <v-icon>monetization_on</v-icon>-->
<!--          </v-list-item-action>-->
<!--          <v-list-item-content>-->
<!--            <v-list-item-title>Discounts</v-list-item-title>-->
<!--          </v-list-item-content>-->
<!--        </v-list-item>-->
        <v-list-item @click="logoutAwait('navigation-logout')" v-if="isLoggedIn()" :disabled="loggingOut">
          <v-list-item-action>
            <v-icon v-if="!loggingOut">exit_to_app</v-icon>
            <v-progress-circular indeterminate v-else></v-progress-circular>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item @click="$router.push('debug')" v-if="isLoggedIn() && $store.state.account && $store.state.account.account.debug" :disabled="$route.path.toLowerCase().indexOf('debug') !== -1">
          <v-list-item-action>
            <v-icon>bug_report</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Debug</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-main class="mb-12">
      <router-view></router-view>
    </v-main>

    <v-snackbar
            v-if="displayUnsupportedBrowser && !$store.state.inApp"
            app
            :top="true"
            :timeout="10000"
            multi-line
            v-model="displayUnsupportedBrowser">
      <v-icon color="accent" class="pr-3">new_releases</v-icon>
      <span>For the best experience, please use <a href="https://www.google.com/chrome">Chrome</a>, <a href="http://getfirefox.com">Firefox</a> or <a href="https://www.apple.com/safari/">Safari</a>.</span>
      <v-btn icon small text color="primary" class="pr-0 pl-3 pt-0 pb-0 ma-0" @click="displayUnsupportedBrowser = false">
        <v-icon>close</v-icon>
      </v-btn>
    </v-snackbar>

    <v-snackbar
      v-else-if="displayPastDue"
      app
      :top="true"
      :timeout="10000"
      multi-line
      v-model="displayPastDue">
      <v-icon color="accent" class="pr-3">new_releases</v-icon>
      <span>Your account is past due. Please <a @click="fixPaymentInfo" class="text--theme--accent text-decoration-underline">fix your card info</a>.</span>
      <v-btn icon small text color="primary" class="pr-0 pl-3 pt-0 pb-0 ma-0" @click="displayPastDue = false">
        <v-icon>close</v-icon>
      </v-btn>
    </v-snackbar>

    <v-snackbar v-else-if="shouldAskForFeedback && !showFeedbackSurvey"
                app
                :right="true"
                :top="true"
                max-width="80vw"
                :multi-line="true"
                :timeout="60000"
                v-model="shouldAskForFeedback"
    >
      <div>
        <v-icon color="accent" class="pr-3">poll</v-icon>
        <span>Let us know how we're doing!</span>
        <v-btn icon small color="accent" class="float-right" @click="overrideFeedbackRequest = true"><v-icon>close</v-icon></v-btn>
      </div>
      <div>
        <v-btn text color="accent" @click="openFeedbackSurvey" class="float-right">
          Take Survey
        </v-btn>
      </div>
    </v-snackbar>
    <v-snackbar
      v-else-if="displayNews"
      app
      :right="true"
      :top="true"
      :timeout="15000"
      v-model="displayNews">
      <template v-if="externalNewsUrl">
        <a :href="externalNewsUrl" target="_blank" @click="snackBarClicked()" class="white--text pa-0 ma-0" style="text-decoration: none;">
          <v-icon color="accent" class="pr-3">new_releases</v-icon>
          {{ newsMessage }}
        </a>
      </template>
<!--      <template v-else>-->
<!--        <router-link to="updates" @click.native="snackBarClicked()" class="white&#45;&#45;text pa-0 ma-0" style="text-decoration: none;">-->
<!--          <v-icon color="accent" class="pr-3">new_releases</v-icon>-->
<!--          {{ newsMessage }}-->
<!--        </router-link>-->
<!--      </template>-->
        <v-btn icon small text color="primary" class="pr-0 pl-3 pt-0 pb-0 ma-0" @click="closeNews"><v-icon>close</v-icon></v-btn>
    </v-snackbar>

    <survey-feedback v-model="showFeedbackSurvey" @on-close="reloadAccount" />

    <v-footer app absolute>
      <v-spacer></v-spacer>
      <div>
        <span class="caption"><a :href="'https://truple.io/privacy.html'" target="_blank" class="caption">Privacy</a>&nbsp;&nbsp;<a :href="'https://truple.io/termsofuse.html'" target="_blank" class="caption">Terms</a> &copy; 2017-2023 Truple LLC&nbsp;&nbsp;v.{{ appVersion }}&nbsp;&nbsp;</span>
      </div>
      <v-spacer></v-spacer>
    </v-footer>
    <terms-of-service></terms-of-service>
  </v-app>
</template>

<script>
import Bowser from "bowser"
import TermsOfService from '@/components/TermsOfService'
import StoreConstants from '@/StoreConstants'
import EventCache from "@/services/EventCache"
import ReferrerHelper from "@/services/ReferrerHelper";
import SurveyFeedback from "@/components/surveys/SurveyFeedback";
const browser = Bowser.getParser(window.navigator.userAgent);

export default {
  name: 'App',
  components: {
    SurveyFeedback,
    TermsOfService
  },
  data() {
    return {
      appName: process.env.VUE_APP_NAME,
      supportDomain: process.env.VUE_APP_SUPPORT_DOMAIN,
      showFeedbackSurvey: false,
      overrideFeedbackRequest: false,
      loggingOut: false,
      drawer: false,
      displayNews: null, //set it below
      newsMessage: 'Enable end-to-end encryption' ,
      externalNewsUrl: 'https://blog.truple.io/2024/09/11/end-to-end-encryption-required.html',
      displayUnsupportedBrowser: null,
      displayPastDue: null,
      appVersion: process.env.VUE_APP_VERSION
    }
  },
  beforeCreate() {
    console.log(`TIMING: App.vue beforeCreate`)
  },
  mounted () {
    console.log(`TIMING: App.vue mounted`)

    ReferrerHelper.run(this.$route.query.href, this.$route.query.referrer)

    const handleNews = async () => {
      await this.$store.state.accountLoadedPromise

      if(!this.$store.getters.isSubscribed && !this.$store.getters.isAccountabilityPartner) {
        //don't want to show this news to everyone.
        return
      }

      this.displayPastDue = this.$store.getters.isAccountPastDue

      let closedAt = JSON.parse(localStorage.getItem('news-closed-at'))

      if(!closedAt) {
        closedAt = 0;
      }

      //e2ee is not enabled and account is more than 30 days old
      this.displayNews = !this.$store.getters.isE2EEEnabled &&
          (
              !this.$store?.state?.account?.account?.createdTimestamp ||
              this.$store.state.account.account.createdTimestamp < (new Date().getTime() - 15 * 24 * 60 * 60 * 1000)
          )

      // this.displayNews = false
      //
      // if(Date.now() - closedAt < 7 * 24 * 60 * 60 * 1000) {
      //   this.displayNews = false
      // } else {
      //   this.displayNews = Date.now() < new Date('2024/06/02').getTime()
      //   // this.displayNews = false
      //   // this.displayNews = true
      // }

      // if(this.$store.state.inApp) {
      //   this.displayNews = false;
      // }
    }

    handleNews()

    window.addEventListener('resize', this.onResize)
    this.onResize()
  },
  beforeDestroy () {
    if (typeof window !== 'undefined') {
      window.removeEventListener('resize', this.onResize)
    }

    EventCache.cleanUp()
  },
  created () {

    let timeoutHandler
    this.$store.watch((state) => state.inAppCredentialsExpiresAt, (oldValue, newValue) => {
      if(newValue) {
        let value = parseInt(newValue)
        if(timeoutHandler) {
          clearTimeout(timeoutHandler)
        }
        timeoutHandler = setTimeout(() => {
          this.api.forceLogout('credentialsExpired')
        }, Math.max(Date.now() - (5 * 60 * 1000) - (value * 1000), 0))
      }
    })

    if(this.$route.query.inApp) {
      this.$store.commit(StoreConstants.commits.setInApp)
    }

    this.$store.commit(StoreConstants.commits.setBrowser, browser.parsedResult)

    this.displayUnsupportedBrowser = this.isUnsupportedBrowser

    this.api.handleIdleLogout()

    window.addEventListener('focus', async () => {
      await this.api.handleIdleLogout()
    })

    window.addEventListener('beforeunload', async () => {
      await this.api.handleIdleLogout()
    })
  },
  methods: {
    openFeedbackSurvey() {
      this.showFeedbackSurvey = true
    },
    reloadAccount() {
      this.$store.dispatch(StoreConstants.actions.loadAccount)
    },
    fixPaymentInfo() {
      this.displayPastDue = false
      this.$router.push({
        path: 'account',
        query: {
          changeCard: true
        }
      })
    },
    closeNews() {
      this.displayNews = false
      localStorage.setItem('news-closed-at', JSON.stringify(Date.now()))
    },
    onResize() {
      let isMobile = window.innerWidth < 600
      this.$store.commit(StoreConstants.commits.setMobile, isMobile)
    },
    snackBarClicked() {
      this.displayNews = false
    },
    isLoggedIn() {
      return this.auth.isLoggedIn()
    },
    async logoutAwait(reason, reload) {
      this.loggingOut = true
      await this.auth.logoutAwait(reason)
      setTimeout(async () => {
        this.loggingOut = false
        if(!reload) {
          await this.$router.push('login')
        } else {
          window.location.reload()
        }
      }, 3000)
    }
  },
  computed: {
    shouldAskForFeedback: {
      get() {
        //disabled by cam on 10/30/2023, setup again once we have it sent to support reps.
        // return false
        return !this.overrideFeedbackRequest && this.$store.getters.shouldAskForFeedback
      },
      set (val) {
        this.overrideFeedbackRequest = val
      }
    },
    whatsNewContentShown() {
      return this.whatsNewContent.filter(x => {
        return !x.hide
      })
    },
    isUnsupportedBrowser() {
      return this.$store.state.browser.engine.name !== 'Blink' && this.$store.state.browser.browser.name !== 'Chrome' && this.$store.state.browser.browser.name !== 'Firefox' && this.$store.state.browser.browser.name !== 'Safari'
    }
  }
}
</script>


<style>

  .text--theme--primary {
    color: #00796B !important;
  }
  .text--theme--accent {
    color: #FFC107 !important;
  }
  .text--theme--secondary {
    color: #757575 !important;
  }

  .text--theme--error {
    color: #b71c1c !important;
  }

  .text--theme--warning {
    color: #f4511e !important;
  }

  #drift-widget-container { z-index: 4 !important;}

  .v-card__text, .v-card__title {
    word-break: normal; /* maybe !important  see https://github.com/vuetifyjs/vuetify/issues/9130 */
  }

  .centered {
    display: table;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }

  .centered2 {
    margin-left:auto;
    margin-right:auto;
    display: table;
  }

  .clickable {
    cursor: pointer;
  }
</style>
