var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    { attrs: { id: "truple" } },
    [
      !_vm.$store.state.inApp
        ? _c(
            "v-app-bar",
            { attrs: { color: "primary", dark: "", fixed: "", app: "" } },
            [
              _vm.isLoggedIn()
                ? _c(
                    "v-expand-transition",
                    [
                      _c("v-app-bar-nav-icon", {
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            _vm.drawer = !_vm.drawer && _vm.isLoggedIn()
                          }
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _c("v-toolbar-title", { staticStyle: { display: "flex" } }, [
                _c("img", {
                  staticStyle: { width: "32px", height: "32px" },
                  attrs: { src: "/static/img/logo.3.white.svg" }
                }),
                _c("span", { staticClass: "ml-2" }, [
                  _vm._v(_vm._s(_vm.appName))
                ])
              ])
            ],
            1
          )
        : _vm._e(),
      !_vm.$store.state.inApp
        ? _c(
            "v-navigation-drawer",
            {
              attrs: { fixed: "", clipped: "", temporary: "", app: "" },
              model: {
                value: _vm.drawer,
                callback: function($$v) {
                  _vm.drawer = $$v
                },
                expression: "drawer"
              }
            },
            [
              _c(
                "v-list",
                { attrs: { dense: "" } },
                [
                  !_vm.isLoggedIn()
                    ? _c(
                        "v-list-item",
                        {
                          on: {
                            click: function($event) {
                              return _vm.$router.push("login")
                            }
                          }
                        },
                        [
                          _c(
                            "v-list-item-action",
                            [_c("v-icon", [_vm._v("account_circle")])],
                            1
                          ),
                          _c(
                            "v-list-item-content",
                            [_c("v-list-item-title", [_vm._v("Sign Up")])],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  !_vm.isLoggedIn()
                    ? _c(
                        "v-list-item",
                        {
                          on: {
                            click: function($event) {
                              return _vm.$router.push("login")
                            }
                          }
                        },
                        [
                          _c(
                            "v-list-item-action",
                            [_c("v-icon", [_vm._v("transit_enterexit")])],
                            1
                          ),
                          _c(
                            "v-list-item-content",
                            [_c("v-list-item-title", [_vm._v("Login")])],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.isLoggedIn()
                    ? _c(
                        "v-list-item",
                        {
                          attrs: {
                            disabled:
                              _vm.$route.path.toLowerCase().indexOf("home") !==
                                -1 ||
                              _vm.$route.path
                                .toLowerCase()
                                .endsWith(".io/#/") ||
                              _vm.$route.path.toLowerCase().endsWith(".io/")
                          },
                          on: {
                            click: function($event) {
                              return _vm.$router.push("home")
                            }
                          }
                        },
                        [
                          _c(
                            "v-list-item-action",
                            [_c("v-icon", [_vm._v("home")])],
                            1
                          ),
                          _c(
                            "v-list-item-content",
                            [_c("v-list-item-title", [_vm._v("Home")])],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.isLoggedIn()
                    ? _c(
                        "v-list-item",
                        {
                          attrs: {
                            disabled:
                              _vm.$route.path
                                .toLowerCase()
                                .indexOf("allscreenshots") !== -1 ||
                              _vm.$route.path
                                .toLowerCase()
                                .endsWith(".io/#/allscreenshots")
                          },
                          on: {
                            click: function($event) {
                              return _vm.$router.push("allscreenshots")
                            }
                          }
                        },
                        [
                          _c(
                            "v-list-item-action",
                            [_c("v-icon", [_vm._v("photo")])],
                            1
                          ),
                          _c(
                            "v-list-item-content",
                            [
                              _c("v-list-item-title", [
                                _vm._v("All Screenshots")
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.isLoggedIn()
                    ? _c(
                        "v-list-item",
                        {
                          attrs: {
                            disabled:
                              _vm.$route.path
                                .toLowerCase()
                                .indexOf("filter") !== -1
                          },
                          on: {
                            click: function($event) {
                              return _vm.$router.push("filter")
                            }
                          }
                        },
                        [
                          _c(
                            "v-list-item-action",
                            [_c("v-icon", [_vm._v("security")])],
                            1
                          ),
                          _c(
                            "v-list-item-content",
                            [_c("v-list-item-title", [_vm._v("Web Filter")])],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.isLoggedIn()
                    ? _c(
                        "v-list-item",
                        {
                          attrs: {
                            disabled:
                              _vm.$route.path
                                .toLowerCase()
                                .indexOf("devices") !== -1
                          },
                          on: {
                            click: function($event) {
                              return _vm.$router.push("devices")
                            }
                          }
                        },
                        [
                          _c(
                            "v-list-item-action",
                            [_c("v-icon", [_vm._v("smartphone")])],
                            1
                          ),
                          _c(
                            "v-list-item-content",
                            [_c("v-list-item-title", [_vm._v("Devices")])],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.isLoggedIn()
                    ? _c(
                        "v-list-item",
                        {
                          attrs: {
                            disabled:
                              _vm.$route.path
                                .toLowerCase()
                                .indexOf("emails") !== -1
                          },
                          on: {
                            click: function($event) {
                              return _vm.$router.push("emails")
                            }
                          }
                        },
                        [
                          _c(
                            "v-list-item-action",
                            [_c("v-icon", [_vm._v("email")])],
                            1
                          ),
                          _c(
                            "v-list-item-content",
                            [
                              _c("v-list-item-title", [
                                _vm._v("Reporting & Alerts")
                              ]),
                              _c("v-list-item-subtitle", [
                                _vm._v("Report Recipients")
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.isLoggedIn()
                    ? _c(
                        "v-list-item",
                        {
                          attrs: {
                            disabled:
                              _vm.$route.path
                                .toLowerCase()
                                .indexOf("account") !== -1
                          },
                          on: {
                            click: function($event) {
                              return _vm.$router.push("account")
                            }
                          }
                        },
                        [
                          _c(
                            "v-list-item-action",
                            [_c("v-icon", [_vm._v("account_box")])],
                            1
                          ),
                          _c(
                            "v-list-item-content",
                            [_c("v-list-item-title", [_vm._v("Account")])],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.isLoggedIn()
                    ? _c(
                        "v-list-item",
                        {
                          attrs: {
                            disabled:
                              _vm.$route.path.toLowerCase().indexOf("setup") !==
                              -1
                          },
                          on: {
                            click: function($event) {
                              return _vm.$router.push("setup")
                            }
                          }
                        },
                        [
                          _c(
                            "v-list-item-action",
                            [_c("v-icon", [_vm._v("done_all")])],
                            1
                          ),
                          _c(
                            "v-list-item-content",
                            [_c("v-list-item-title", [_vm._v("Setup Guide")])],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "v-list-item",
                    {
                      attrs: {
                        href: "https://" + _vm.supportDomain,
                        target: "_blank"
                      }
                    },
                    [
                      _c(
                        "v-list-item-action",
                        [_c("v-icon", [_vm._v("help")])],
                        1
                      ),
                      _c(
                        "v-list-item-content",
                        [_c("v-list-item-title", [_vm._v("Support")])],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-list-item",
                    {
                      attrs: {
                        href: "https://stories.truple.io",
                        target: "_blank"
                      }
                    },
                    [
                      _c(
                        "v-list-item-action",
                        [_c("v-icon", [_vm._v("comment")])],
                        1
                      ),
                      _c(
                        "v-list-item-content",
                        [_c("v-list-item-title", [_vm._v("Community")])],
                        1
                      )
                    ],
                    1
                  ),
                  _vm.isLoggedIn()
                    ? _c(
                        "v-list-item",
                        {
                          attrs: {
                            disabled:
                              _vm.$route.path
                                .toLowerCase()
                                .indexOf("rating") !== -1 ||
                              _vm.$route.path
                                .toLowerCase()
                                .endsWith(".io/#/rating")
                          },
                          on: {
                            click: function($event) {
                              return _vm.$router.push("rating")
                            }
                          }
                        },
                        [
                          _c(
                            "v-list-item-action",
                            [_c("v-icon", [_vm._v("construction")])],
                            1
                          ),
                          _c(
                            "v-list-item-content",
                            [_c("v-list-item-title", [_vm._v("Analyzer")])],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.isLoggedIn()
                    ? _c(
                        "v-list-item",
                        {
                          attrs: { disabled: _vm.loggingOut },
                          on: {
                            click: function($event) {
                              return _vm.logoutAwait("navigation-logout")
                            }
                          }
                        },
                        [
                          _c(
                            "v-list-item-action",
                            [
                              !_vm.loggingOut
                                ? _c("v-icon", [_vm._v("exit_to_app")])
                                : _c("v-progress-circular", {
                                    attrs: { indeterminate: "" }
                                  })
                            ],
                            1
                          ),
                          _c(
                            "v-list-item-content",
                            [_c("v-list-item-title", [_vm._v("Logout")])],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.isLoggedIn() &&
                  _vm.$store.state.account &&
                  _vm.$store.state.account.account.debug
                    ? _c(
                        "v-list-item",
                        {
                          attrs: {
                            disabled:
                              _vm.$route.path.toLowerCase().indexOf("debug") !==
                              -1
                          },
                          on: {
                            click: function($event) {
                              return _vm.$router.push("debug")
                            }
                          }
                        },
                        [
                          _c(
                            "v-list-item-action",
                            [_c("v-icon", [_vm._v("bug_report")])],
                            1
                          ),
                          _c(
                            "v-list-item-content",
                            [_c("v-list-item-title", [_vm._v("Debug")])],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c("v-main", { staticClass: "mb-12" }, [_c("router-view")], 1),
      _vm.displayUnsupportedBrowser && !_vm.$store.state.inApp
        ? _c(
            "v-snackbar",
            {
              attrs: { app: "", top: true, timeout: 10000, "multi-line": "" },
              model: {
                value: _vm.displayUnsupportedBrowser,
                callback: function($$v) {
                  _vm.displayUnsupportedBrowser = $$v
                },
                expression: "displayUnsupportedBrowser"
              }
            },
            [
              _c(
                "v-icon",
                { staticClass: "pr-3", attrs: { color: "accent" } },
                [_vm._v("new_releases")]
              ),
              _c("span", [
                _vm._v("For the best experience, please use "),
                _c("a", { attrs: { href: "https://www.google.com/chrome" } }, [
                  _vm._v("Chrome")
                ]),
                _vm._v(", "),
                _c("a", { attrs: { href: "http://getfirefox.com" } }, [
                  _vm._v("Firefox")
                ]),
                _vm._v(" or "),
                _c("a", { attrs: { href: "https://www.apple.com/safari/" } }, [
                  _vm._v("Safari")
                ]),
                _vm._v(".")
              ]),
              _c(
                "v-btn",
                {
                  staticClass: "pr-0 pl-3 pt-0 pb-0 ma-0",
                  attrs: { icon: "", small: "", text: "", color: "primary" },
                  on: {
                    click: function($event) {
                      _vm.displayUnsupportedBrowser = false
                    }
                  }
                },
                [_c("v-icon", [_vm._v("close")])],
                1
              )
            ],
            1
          )
        : _vm.displayPastDue
        ? _c(
            "v-snackbar",
            {
              attrs: { app: "", top: true, timeout: 10000, "multi-line": "" },
              model: {
                value: _vm.displayPastDue,
                callback: function($$v) {
                  _vm.displayPastDue = $$v
                },
                expression: "displayPastDue"
              }
            },
            [
              _c(
                "v-icon",
                { staticClass: "pr-3", attrs: { color: "accent" } },
                [_vm._v("new_releases")]
              ),
              _c("span", [
                _vm._v("Your account is past due. Please "),
                _c(
                  "a",
                  {
                    staticClass:
                      "text--theme--accent text-decoration-underline",
                    on: { click: _vm.fixPaymentInfo }
                  },
                  [_vm._v("fix your card info")]
                ),
                _vm._v(".")
              ]),
              _c(
                "v-btn",
                {
                  staticClass: "pr-0 pl-3 pt-0 pb-0 ma-0",
                  attrs: { icon: "", small: "", text: "", color: "primary" },
                  on: {
                    click: function($event) {
                      _vm.displayPastDue = false
                    }
                  }
                },
                [_c("v-icon", [_vm._v("close")])],
                1
              )
            ],
            1
          )
        : _vm.shouldAskForFeedback && !_vm.showFeedbackSurvey
        ? _c(
            "v-snackbar",
            {
              attrs: {
                app: "",
                right: true,
                top: true,
                "max-width": "80vw",
                "multi-line": true,
                timeout: 60000
              },
              model: {
                value: _vm.shouldAskForFeedback,
                callback: function($$v) {
                  _vm.shouldAskForFeedback = $$v
                },
                expression: "shouldAskForFeedback"
              }
            },
            [
              _c(
                "div",
                [
                  _c(
                    "v-icon",
                    { staticClass: "pr-3", attrs: { color: "accent" } },
                    [_vm._v("poll")]
                  ),
                  _c("span", [_vm._v("Let us know how we're doing!")]),
                  _c(
                    "v-btn",
                    {
                      staticClass: "float-right",
                      attrs: { icon: "", small: "", color: "accent" },
                      on: {
                        click: function($event) {
                          _vm.overrideFeedbackRequest = true
                        }
                      }
                    },
                    [_c("v-icon", [_vm._v("close")])],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "float-right",
                      attrs: { text: "", color: "accent" },
                      on: { click: _vm.openFeedbackSurvey }
                    },
                    [_vm._v("\n          Take Survey\n        ")]
                  )
                ],
                1
              )
            ]
          )
        : _vm.displayNews
        ? _c(
            "v-snackbar",
            {
              attrs: { app: "", right: true, top: true, timeout: 15000 },
              model: {
                value: _vm.displayNews,
                callback: function($$v) {
                  _vm.displayNews = $$v
                },
                expression: "displayNews"
              }
            },
            [
              _vm.externalNewsUrl
                ? [
                    _c(
                      "a",
                      {
                        staticClass: "white--text pa-0 ma-0",
                        staticStyle: { "text-decoration": "none" },
                        attrs: { href: _vm.externalNewsUrl, target: "_blank" },
                        on: {
                          click: function($event) {
                            return _vm.snackBarClicked()
                          }
                        }
                      },
                      [
                        _c(
                          "v-icon",
                          { staticClass: "pr-3", attrs: { color: "accent" } },
                          [_vm._v("new_releases")]
                        ),
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.newsMessage) +
                            "\n        "
                        )
                      ],
                      1
                    )
                  ]
                : _vm._e(),
              _c(
                "v-btn",
                {
                  staticClass: "pr-0 pl-3 pt-0 pb-0 ma-0",
                  attrs: { icon: "", small: "", text: "", color: "primary" },
                  on: { click: _vm.closeNews }
                },
                [_c("v-icon", [_vm._v("close")])],
                1
              )
            ],
            2
          )
        : _vm._e(),
      _c("survey-feedback", {
        on: { "on-close": _vm.reloadAccount },
        model: {
          value: _vm.showFeedbackSurvey,
          callback: function($$v) {
            _vm.showFeedbackSurvey = $$v
          },
          expression: "showFeedbackSurvey"
        }
      }),
      _c(
        "v-footer",
        { attrs: { app: "", absolute: "" } },
        [
          _c("v-spacer"),
          _c("div", [
            _c("span", { staticClass: "caption" }, [
              _c(
                "a",
                {
                  staticClass: "caption",
                  attrs: {
                    href: "https://truple.io/privacy.html",
                    target: "_blank"
                  }
                },
                [_vm._v("Privacy")]
              ),
              _vm._v("  "),
              _c(
                "a",
                {
                  staticClass: "caption",
                  attrs: {
                    href: "https://truple.io/termsofuse.html",
                    target: "_blank"
                  }
                },
                [_vm._v("Terms")]
              ),
              _vm._v(
                " © 2017-2023 Truple LLC  v." + _vm._s(_vm.appVersion) + "  "
              )
            ])
          ]),
          _c("v-spacer")
        ],
        1
      ),
      _c("terms-of-service")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }