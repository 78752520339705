/*
 * Copyright 2010-2016 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License").
 * You may not use this file except in compliance with the License.
 * A copy of the License is located at
 *
 *  http://aws.amazon.com/apache2.0
 *
 * or in the "license" file accompanying this file. This file is distributed
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either
 * express or implied. See the License for the specific language governing
 * permissions and limitations under the License.
 */

import utils from '../apiGatewayCore/utils';
import { apiGatewayClientFactory } from '../apiGatewayCore/apiGatewayClient.js'
import uritemplate from "../apiGatewayCore/url-template"

var accountClientFactory = {};
accountClientFactory.newClient = function (config, invokeUrl) {
    var apigClient = { };
    if(config === undefined) {
        config = {
            accessKey: '',
            secretKey: '',
            sessionToken: '',
            region: '',
            apiKey: undefined,
            defaultContentType: 'application/json',
            defaultAcceptType: 'application/json'
        };
    }
    if(config.accessKey === undefined) {
        config.accessKey = '';
    }
    if(config.secretKey === undefined) {
        config.secretKey = '';
    }
    if(config.apiKey === undefined) {
        config.apiKey = '';
    }
    if(config.sessionToken === undefined) {
        config.sessionToken = '';
    }
    if(config.region === undefined) {
        config.region = 'us-east-1';
    }
    //If defaultContentType is not defined then default to application/json
    if(config.defaultContentType === undefined) {
        config.defaultContentType = 'application/json';
    }
    //If defaultAcceptType is not defined then default to application/json
    if(config.defaultAcceptType === undefined) {
        config.defaultAcceptType = 'application/json';
    }


    // extract endpoint and path from url
    var endpoint = /(^https?:\/\/[^\/]+)/g.exec(invokeUrl)[1];
    var pathComponent = invokeUrl.substring(endpoint.length);

    var sigV4ClientConfig = {
        accessKey: config.accessKey,
        secretKey: config.secretKey,
        sessionToken: config.sessionToken,
        serviceName: 'execute-api',
        region: config.region,
        endpoint: endpoint,
        defaultContentType: config.defaultContentType,
        defaultAcceptType: config.defaultAcceptType
    };

    var authType = 'NONE';
    if (sigV4ClientConfig.accessKey !== undefined && sigV4ClientConfig.accessKey !== '' && sigV4ClientConfig.secretKey !== undefined && sigV4ClientConfig.secretKey !== '') {
        authType = 'AWS_IAM';
    }

    var simpleHttpClientConfig = {
        endpoint: endpoint,
        defaultContentType: config.defaultContentType,
        defaultAcceptType: config.defaultAcceptType
    };

    var apiGatewayClient = apiGatewayClientFactory.newClient(simpleHttpClientConfig, sigV4ClientConfig);



    // apigClient.accountsInviteCodePut = function (params, body, additionalParams) {
    //     if(additionalParams === undefined) { additionalParams = {}; }
    //
    //     utils.assertParametersDefined(params, ['body', 'deviceUUID', 'os'], ['body']);
    //
    //     var accountsInviteCodePutRequest = {
    //         verb: 'put'.toUpperCase(),
    //         path: pathComponent + uritemplate('/accounts/inviteCode').expand(utils.parseParametersToObject(params, [])),
    //         headers: utils.parseParametersToObject(params, []),
    //         queryParams: utils.parseParametersToObject(params, ['deviceUUID', 'os']),
    //         body: body
    //     };
    //
    //
    //     return apiGatewayClient.makeRequest(accountsInviteCodePutRequest, authType, additionalParams, config.apiKey);
    // };
    //
    //
    // apigClient.accountsInviteCodeOptions = function (params, body, additionalParams) {
    //     if(additionalParams === undefined) { additionalParams = {}; }
    //
    //     utils.assertParametersDefined(params, [], ['body']);
    //
    //     var accountsInviteCodeOptionsRequest = {
    //         verb: 'options'.toUpperCase(),
    //         path: pathComponent + uritemplate('/accounts/inviteCode').expand(utils.parseParametersToObject(params, [])),
    //         headers: utils.parseParametersToObject(params, []),
    //         queryParams: utils.parseParametersToObject(params, []),
    //         body: body
    //     };
    //
    //
    //     return apiGatewayClient.makeRequest(accountsInviteCodeOptionsRequest, authType, additionalParams, config.apiKey);
    // };
    //
    //
    // apigClient.accountsWithoutBillingGet = function (params, body, additionalParams) {
    //     if(additionalParams === undefined) { additionalParams = {}; }
    //
    //     utils.assertParametersDefined(params, ['deviceUUID'], ['body']);
    //
    //     var accountsWithoutBillingGetRequest = {
    //         verb: 'get'.toUpperCase(),
    //         path: pathComponent + uritemplate('/accounts/withoutBilling').expand(utils.parseParametersToObject(params, [])),
    //         headers: utils.parseParametersToObject(params, []),
    //         queryParams: utils.parseParametersToObject(params, ['deviceUUID']),
    //         body: body
    //     };
    //
    //
    //     return apiGatewayClient.makeRequest(accountsWithoutBillingGetRequest, authType, additionalParams, config.apiKey);
    // };
    //
    //
    // apigClient.accountsWithoutBillingOptions = function (params, body, additionalParams) {
    //     if(additionalParams === undefined) { additionalParams = {}; }
    //
    //     utils.assertParametersDefined(params, [], ['body']);
    //
    //     var accountsWithoutBillingOptionsRequest = {
    //         verb: 'options'.toUpperCase(),
    //         path: pathComponent + uritemplate('/accounts/withoutBilling').expand(utils.parseParametersToObject(params, [])),
    //         headers: utils.parseParametersToObject(params, []),
    //         queryParams: utils.parseParametersToObject(params, []),
    //         body: body
    //     };
    //
    //
    //     return apiGatewayClient.makeRequest(accountsWithoutBillingOptionsRequest, authType, additionalParams, config.apiKey);
    // };
    //
    //
    // apigClient.debugPost = function (params, body, additionalParams) {
    //     if(additionalParams === undefined) { additionalParams = {}; }
    //
    //     utils.assertParametersDefined(params, ['body'], ['body']);
    //
    //     var debugPostRequest = {
    //         verb: 'post'.toUpperCase(),
    //         path: pathComponent + uritemplate('/debug').expand(utils.parseParametersToObject(params, [])),
    //         headers: utils.parseParametersToObject(params, []),
    //         queryParams: utils.parseParametersToObject(params, []),
    //         body: body
    //     };
    //
    //
    //     return apiGatewayClient.makeRequest(debugPostRequest, authType, additionalParams, config.apiKey);
    // };
    //
    //
    // apigClient.devicesAddPost = function (params, body, additionalParams) {
    //     if(additionalParams === undefined) { additionalParams = {}; }
    //
    //     utils.assertParametersDefined(params, ['body'], ['body']);
    //
    //     var devicesAddPostRequest = {
    //         verb: 'post'.toUpperCase(),
    //         path: pathComponent + uritemplate('/devices/add').expand(utils.parseParametersToObject(params, [])),
    //         headers: utils.parseParametersToObject(params, []),
    //         queryParams: utils.parseParametersToObject(params, []),
    //         body: body
    //     };
    //
    //
    //     return apiGatewayClient.makeRequest(devicesAddPostRequest, authType, additionalParams, config.apiKey);
    // };
    //
    //
    // apigClient.devicesAddOptions = function (params, body, additionalParams) {
    //     if(additionalParams === undefined) { additionalParams = {}; }
    //
    //     utils.assertParametersDefined(params, [], ['body']);
    //
    //     var devicesAddOptionsRequest = {
    //         verb: 'options'.toUpperCase(),
    //         path: pathComponent + uritemplate('/devices/add').expand(utils.parseParametersToObject(params, [])),
    //         headers: utils.parseParametersToObject(params, []),
    //         queryParams: utils.parseParametersToObject(params, []),
    //         body: body
    //     };
    //
    //
    //     return apiGatewayClient.makeRequest(devicesAddOptionsRequest, authType, additionalParams, config.apiKey);
    // };


    apigClient.devicesDohAddPost = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }

        utils.assertParametersDefined(params, [], ['body']);

        var devicesDohAddPostRequest = {
            verb: 'post'.toUpperCase(),
            path: pathComponent + uritemplate('/devices/doh/add').expand(utils.parseParametersToObject(params, [])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };


        return apiGatewayClient.makeRequest(devicesDohAddPostRequest, authType, additionalParams, config.apiKey);
    };


    apigClient.devicesDohAddOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }

        utils.assertParametersDefined(params, [], ['body']);

        var devicesDohAddOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/devices/doh/add').expand(utils.parseParametersToObject(params, [])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };


        return apiGatewayClient.makeRequest(devicesDohAddOptionsRequest, authType, additionalParams, config.apiKey);
    };


    // apigClient.devicesMigratePost = function (params, body, additionalParams) {
    //     if(additionalParams === undefined) { additionalParams = {}; }
    //
    //     utils.assertParametersDefined(params, ['body'], ['body']);
    //
    //     var devicesMigratePostRequest = {
    //         verb: 'post'.toUpperCase(),
    //         path: pathComponent + uritemplate('/devices/migrate').expand(utils.parseParametersToObject(params, [])),
    //         headers: utils.parseParametersToObject(params, []),
    //         queryParams: utils.parseParametersToObject(params, []),
    //         body: body
    //     };
    //
    //
    //     return apiGatewayClient.makeRequest(devicesMigratePostRequest, authType, additionalParams, config.apiKey);
    // };
    //

    apigClient.accountsE2eePut = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }

        utils.assertParametersDefined(params, [], ['body']);

        var accountsE2eePutRequest = {
            verb: 'put'.toUpperCase(),
            path: pathComponent + uritemplate('/accounts/e2ee').expand(utils.parseParametersToObject(params, [])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };


        return apiGatewayClient.makeRequest(accountsE2eePutRequest, authType, additionalParams, config.apiKey);
    };


    apigClient.accountsE2eeOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }

        utils.assertParametersDefined(params, [], ['body']);

        var accountsE2eeOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/accounts/e2ee').expand(utils.parseParametersToObject(params, [])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };


        return apiGatewayClient.makeRequest(accountsE2eeOptionsRequest, authType, additionalParams, config.apiKey);
    };

    apigClient.e2eGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }

        utils.assertParametersDefined(params, ['deviceUUID', 'forAccountId'], ['body']);

        var e2eGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/e2e').expand(utils.parseParametersToObject(params, [])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, ['deviceUUID', 'forAccountId']),
            body: body
        };


        return apiGatewayClient.makeRequest(e2eGetRequest, authType, additionalParams, config.apiKey);
    };


    apigClient.e2eOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }

        utils.assertParametersDefined(params, [], ['body']);

        var e2eOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/e2e').expand(utils.parseParametersToObject(params, [])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };


        return apiGatewayClient.makeRequest(e2eOptionsRequest, authType, additionalParams, config.apiKey);
    };

    apigClient.devicesRequestBlockPost = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }

        utils.assertParametersDefined(params, ['ip', 'deviceUUID'], ['body']);

        var devicesRequestRejectPostRequest = {
            verb: 'post'.toUpperCase(),
            path: pathComponent + uritemplate('/devices/request/block').expand(utils.parseParametersToObject(params, [])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, ['deviceUUID', 'ip']),
            body: body
        };

        return apiGatewayClient.makeRequest(devicesRequestRejectPostRequest, authType, additionalParams, config.apiKey);
    };

    apigClient.devicesRequestRejectPost = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }

        utils.assertParametersDefined(params, ['body', 'deviceUUID'], ['body']);

        var devicesRequestRejectPostRequest = {
            verb: 'post'.toUpperCase(),
            path: pathComponent + uritemplate('/devices/request/reject').expand(utils.parseParametersToObject(params, [])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, ['deviceUUID']),
            body: body
        };


        return apiGatewayClient.makeRequest(devicesRequestRejectPostRequest, authType, additionalParams, config.apiKey);
    };

    apigClient.devicesRequestGrantPost = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }

        utils.assertParametersDefined(params, ['body', 'deviceUUID'], ['body']);

        var devicesRequestGrantPostRequest = {
            verb: 'post'.toUpperCase(),
            path: pathComponent + uritemplate('/devices/request/grant').expand(utils.parseParametersToObject(params, [])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, ['deviceUUID']),
            body: body
        };


        return apiGatewayClient.makeRequest(devicesRequestGrantPostRequest, authType, additionalParams, config.apiKey);
    };

    apigClient.accountDelete = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }

        utils.assertParametersDefined(params, ['deviceUUID'], ['body']);

        var accountDeleteRequest = {
            verb: 'delete'.toUpperCase(),
            path: pathComponent + uritemplate('/account').expand(utils.parseParametersToObject(params, [])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, ['deviceUUID']),
            body: body
        };


        return apiGatewayClient.makeRequest(accountDeleteRequest, authType, additionalParams, config.apiKey);
    };


    // apigClient.accountOptions = function (params, body, additionalParams) {
    //     if(additionalParams === undefined) { additionalParams = {}; }
    //
    //     utils.assertParametersDefined(params, [], ['body']);
    //
    //     var accountOptionsRequest = {
    //         verb: 'options'.toUpperCase(),
    //         path: pathComponent + uritemplate('/account').expand(utils.parseParametersToObject(params, [])),
    //         headers: utils.parseParametersToObject(params, []),
    //         queryParams: utils.parseParametersToObject(params, []),
    //         body: body
    //     };
    //
    //
    //     return apiGatewayClient.makeRequest(accountOptionsRequest, authType, additionalParams, config.apiKey);
    // };

    //
    // apigClient.versionsGet = function (params, body, additionalParams) {
    //     if(additionalParams === undefined) { additionalParams = {}; }
    //
    //     utils.assertParametersDefined(params, ['currentVersion'], ['body']);
    //
    //     var versionsGetRequest = {
    //         verb: 'get'.toUpperCase(),
    //         path: pathComponent + uritemplate('/versions').expand(utils.parseParametersToObject(params, [])),
    //         headers: utils.parseParametersToObject(params, []),
    //         queryParams: utils.parseParametersToObject(params, ['currentVersion']),
    //         body: body
    //     };
    //
    //
    //     return apiGatewayClient.makeRequest(versionsGetRequest, authType, additionalParams, config.apiKey);
    // };


    return apigClient;
};

export default accountClientFactory
