var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "amplify-card",
    [
      _c("v-card-title", { staticClass: "d-flex justify-center" }, [
        !_vm.$store.state.isMobile
          ? _c("img", {
              staticStyle: { width: "128px", height: "128px" },
              attrs: { src: "/static/img/logo.3.white.svg" }
            })
          : _vm._e(),
        _c(
          "div",
          { staticClass: "text-center", staticStyle: { width: "100%" } },
          [_vm._v("Sign in to " + _vm._s(_vm.appName))]
        )
      ]),
      _c(
        "v-card-text",
        [
          _c(
            "div",
            [
              _vm.myAuthData.email &&
              _vm.myAuthData.email.endsWith(".decryption.passcode")
                ? _c("v-alert", { attrs: { type: "warning" } }, [
                    _vm._v(
                      "\n        Please correct your email address!  It doesn't end with '.decryption.passcode'.\n      "
                    )
                  ])
                : _vm._e(),
              _c("v-text-field", {
                attrs: {
                  outlined: "",
                  autofocus: "",
                  label: "Email Address",
                  placeholder: "",
                  type: "email",
                  autocomplete: "username",
                  name: "usr",
                  disabled: _vm.loading
                },
                on: { change: _vm.authDataChange },
                model: {
                  value: _vm.myAuthData.email,
                  callback: function($$v) {
                    _vm.$set(_vm.myAuthData, "email", $$v)
                  },
                  expression: "myAuthData.email"
                }
              }),
              _c("v-text-field", {
                attrs: {
                  outlined: "",
                  label: "Password",
                  placeholder: "",
                  type: _vm.showPassword ? "text" : "password",
                  "append-icon": _vm.showPassword
                    ? "visibility_off"
                    : "visibility",
                  disabled: _vm.loading,
                  autocomplete: ""
                },
                on: {
                  change: _vm.authDataChange,
                  "click:append": _vm.togglePasswordVisibility
                },
                model: {
                  value: _vm.myAuthData.password,
                  callback: function($$v) {
                    _vm.$set(_vm.myAuthData, "password", $$v)
                  },
                  expression: "myAuthData.password"
                }
              }),
              _c(
                "div",
                {
                  staticClass: "mb-2",
                  staticStyle: { "margin-top": "-22px", "z-index": "10" }
                },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "pl-2 text-none caption",
                      staticStyle: { cursor: "pointer" },
                      attrs: { text: "", color: "primary", "x-small": "" },
                      on: { click: _vm.switchToForgotPassword }
                    },
                    [_vm._v("Forgot your password?")]
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "d-flex", staticStyle: { width: "100%" } },
                [
                  _c(
                    "div",
                    { staticClass: "d-flex align-center" },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "pl-2 text-none",
                          staticStyle: {
                            cursor: "pointer",
                            "letter-spacing": "normal"
                          },
                          attrs: { text: "", color: "primary" },
                          on: { click: _vm.registerAccount }
                        },
                        [_vm._v("Create account")]
                      )
                    ],
                    1
                  ),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      staticClass: "pl-10 pr-10 float-right",
                      attrs: {
                        color: "primary",
                        large: "",
                        disabled: _vm.loading,
                        loading: _vm.loading
                      },
                      on: { click: _vm.signIn }
                    },
                    [_vm._v("\n          Sign In\n        ")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "pt-1", staticStyle: { width: "100%" } },
            [
              _c("v-checkbox", {
                staticClass: "ma-0 pa-0 float-right",
                staticStyle: {
                  height: "28px !important",
                  width: "24px !important"
                },
                attrs: { dense: "" },
                on: { change: _vm.persistLoginChange },
                model: {
                  value: _vm.persistLogin,
                  callback: function($$v) {
                    _vm.persistLogin = $$v
                  },
                  expression: "persistLogin"
                }
              }),
              _c(
                "span",
                {
                  staticClass: "caption float-right",
                  staticStyle: {
                    "letter-spacing": "normal !important",
                    "padding-top": "2px"
                  }
                },
                [_vm._v("stay logged in for 30 days")]
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "d-flex", staticStyle: { width: "100%" } },
            [
              _c(
                "v-btn",
                {
                  staticClass: "pl-2 text-none caption",
                  staticStyle: { cursor: "pointer" },
                  attrs: { text: "", color: "primary", "x-small": "" },
                  on: { click: _vm.issuesLoggingIn }
                },
                [_vm._v("Issues logging in?")]
              )
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "hr-sect pt-0 pb-2",
              staticStyle: { width: "100%" }
            },
            [_vm._v("\n      or\n    ")]
          ),
          _c("google-o-auth-button", { on: { "on-trigger": _vm.googleOAuth } }),
          _c("div", { staticClass: "caption text-center mt-4" }, [
            _vm._v("\n      By signing in you agree to Truple's "),
            _vm.$store.state.isMobile ? _c("br") : _vm._e(),
            _c(
              "a",
              {
                attrs: {
                  href: "https://truple.io/termsofuse.html",
                  target: "_blank"
                }
              },
              [_vm._v("Terms")]
            ),
            _vm._v(" and "),
            _c(
              "a",
              {
                attrs: {
                  href: "https://truple.io/privacy.html",
                  target: "_blank"
                }
              },
              [_vm._v("Privacy Policy")]
            ),
            _vm._v(".\n    ")
          ]),
          _c(
            "v-dialog",
            {
              attrs: { "max-width": "600" },
              model: {
                value: _vm.newPasswordDialog,
                callback: function($$v) {
                  _vm.newPasswordDialog = $$v
                },
                expression: "newPasswordDialog"
              }
            },
            [
              _c(
                "v-card",
                [
                  _c("v-card-title", [_vm._v("New Password Required")]),
                  _c(
                    "v-card-text",
                    [
                      _c("div", { staticClass: "mb-6" }, [
                        _vm._v(
                          "\n            Please provide a new password\n          "
                        )
                      ]),
                      _c("password-text-field", {
                        attrs: { "show-confirm": false },
                        on: {
                          "auth-data-change": _vm.authDataChange,
                          "on-password-valid-change": _vm.passwordValidChange
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            color: "primary",
                            disabled: !_vm.isPasswordValid || _vm.loading,
                            loading: _vm.loading
                          },
                          on: { click: _vm.submitNewPassword }
                        },
                        [_vm._v("\n            Submit\n          ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-dialog",
            {
              attrs: { "max-width": "400" },
              model: {
                value: _vm.verifyEmailDialog,
                callback: function($$v) {
                  _vm.verifyEmailDialog = $$v
                },
                expression: "verifyEmailDialog"
              }
            },
            [
              _c(
                "v-card",
                [
                  _c("v-card-title", [
                    _vm._v("\n          Verify email\n        ")
                  ]),
                  _c(
                    "v-card-text",
                    [
                      _c("div", { staticClass: "mb-6" }, [
                        _vm._v(
                          "We just sent an email verification code to your inbox.  Please enter the code to verify your account."
                        )
                      ]),
                      _c("v-text-field", {
                        attrs: {
                          outlined: "",
                          label: "Verification Code",
                          placeholder: ""
                        },
                        model: {
                          value: _vm.verifyCode,
                          callback: function($$v) {
                            _vm.verifyCode = $$v
                          },
                          expression: "verifyCode"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            color: "primary",
                            disabled: !_vm.verifyCode || _vm.loading,
                            loading: _vm.loading
                          },
                          on: { click: _vm.verifyEmail }
                        },
                        [_vm._v("Verify")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }