<template>
  <amplify-card>
    <v-card-title class="d-flex justify-center">
      <img v-if="!$store.state.isMobile" src="/static/img/logo.3.white.svg" style="width: 128px; height: 128px;" class="" />
      <div style="width: 100%;" class="text-center">Sign in to {{ appName }}</div>
    </v-card-title>
    <v-card-text>
      <div>

        <v-alert type="warning" v-if="myAuthData.email && myAuthData.email.endsWith('.decryption.passcode')">
          Please correct your email address!  It doesn't end with '.decryption.passcode'.
        </v-alert>

        <v-text-field
            outlined
            v-model="myAuthData.email"
            @change="authDataChange"
            autofocus
            label="Email Address"
            placeholder=""
            type="email"
            autocomplete="username"
            name="usr"
            :disabled="loading"
        />

        <v-text-field
            outlined
            v-model="myAuthData.password"
            @change="authDataChange"
            label="Password"
            placeholder=""
            :type="showPassword ? 'text' : 'password'"
            @click:append="togglePasswordVisibility"
            :append-icon="showPassword ? 'visibility_off' : 'visibility'"
            :disabled="loading"
            autocomplete
        />
        <div style="margin-top: -22px; z-index: 10;" class="mb-2">
          <v-btn text @click="switchToForgotPassword" class="pl-2 text-none caption" color="primary" style="cursor: pointer;" x-small>Forgot your password?</v-btn>
        </div>

        <div style="width: 100%;" class="d-flex">
          <div class="d-flex align-center">
            <v-btn text @click="registerAccount" class="pl-2 text-none" color="primary" style="cursor: pointer; letter-spacing: normal;">Create account</v-btn>
          </div>
          <v-spacer/>
          <v-btn color="primary" @click="signIn" class="pl-10 pr-10 float-right" large :disabled="loading" :loading="loading">
            Sign In
          </v-btn>
        </div>
      </div>
      <div style="width: 100%;" class="pt-1">
        <v-checkbox dense v-model="persistLogin" @change="persistLoginChange" class="ma-0 pa-0 float-right" style="height: 28px !important; width: 24px !important;" />
        <span class="caption float-right" style="letter-spacing: normal !important; padding-top: 2px;">stay logged in for 30 days</span>
      </div>

      <div style="width: 100%;" class="d-flex">
        <v-btn text @click="issuesLoggingIn" class="pl-2 text-none caption" color="primary" style="cursor: pointer;" x-small>Issues logging in?</v-btn>
      </div>

      <div style="width: 100%;" class="hr-sect pt-0 pb-2">
        or
      </div>

      <google-o-auth-button @on-trigger="googleOAuth" />

      <div class="caption text-center mt-4">
        By signing in you agree to Truple's <br v-if="$store.state.isMobile" /><a href="https://truple.io/termsofuse.html" target="_blank">Terms</a> and <a href="https://truple.io/privacy.html" target="_blank">Privacy Policy</a>.
      </div>

      <v-dialog v-model="newPasswordDialog" max-width="600">
        <v-card>
          <v-card-title>New Password Required</v-card-title>
          <v-card-text>
            <div class="mb-6">
              Please provide a new password
            </div>

            <password-text-field
                @auth-data-change="authDataChange"
                @on-password-valid-change="passwordValidChange"
                :show-confirm="false"
            />
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="submitNewPassword" :disabled="!isPasswordValid || loading" :loading="loading">
              Submit
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="verifyEmailDialog" max-width="400">
        <v-card>
          <v-card-title>
            Verify email
          </v-card-title>
          <v-card-text>
            <div class="mb-6">We just sent an email verification code to your inbox.  Please enter the code to verify your account.</div>
            <v-text-field
                outlined
                v-model="verifyCode"
                label="Verification Code"
                placeholder=""
            />
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="verifyEmail" :disabled="!verifyCode || loading" :loading="loading">Verify</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card-text>
  </amplify-card>
</template>

<script>
import StoreConstants from "@/StoreConstants";
import GoogleOAuthButton from "@/components/amplify/GoogleOAuthButton";
import AmplifyCard from "@/components/amplify/AmplifyCard";
import AuthPages from "@/components/amplify/AuthPages";
import Auth from "@aws-amplify/auth";
import PasswordTextField from "@/components/amplify/PasswordTextField";

// async function printCurrentCredentials() {
//   try {
//     let creds = await Auth.currentCredentials()
//     console.log('got creds')
//     console.log(creds)
//   } catch(e) {
//     console.log(e)
//   }
// }
//
// printCurrentCredentials()

export default {
name: "SignIn",
  components: {
    PasswordTextField,
    AmplifyCard,
    GoogleOAuthButton
  },
  props: {
    authData: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      showPassword: false,
      appName: process.env.VUE_APP_NAME,
      verifyEmailDialog: false,
      verifyCode: '',
      persistLogin: this.$store.state.keepMeLoggedIn,
      newPasswordDialog: false,
      loading: false,
      myAuthData: this.authData,
      isPasswordValid: false
    }
  },
  methods: {
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },
    issuesLoggingIn() {
      Auth.federatedSignIn()
    },
    goToConfirmAccountPage() {
      this.$emit('page', AuthPages.confirmAccount)
    },
    async verifyEmailAddress() {
      try {
        this.loading = true
        let r = await Auth.verifyCurrentUserAttributeSubmit('email', this.verifyCode)
        console.log(r)
        this.verifyEmailDialog = false
        await Auth.signOut() //if we don't sign out and log back in, then email_verified stays false.
        this.signIn()
      } catch(e) {
        console.log('verifyEmail error')
        console.log(e)
        this.myAuthData.error = e
        this.authDataChange()
      } finally {
        this.loading = false
      }
    },
    async verifyEmail() {
      try {
        this.loading = true
        let r = await Auth.verifyCurrentUserAttributeSubmit('email', this.verifyCode)
        console.log(r)
        this.verifyEmailDialog = false
        await Auth.signOut() //if we don't sign out and log back in, then email_verified stays false.
        this.signIn()
      } catch(e) {
        console.log('verifyEmail error')
        console.log(e)
        this.myAuthData.error = e
        this.authDataChange()
      } finally {
        this.loading = false
      }
    },
    persistLoginChange() {
      console.log(`persist login = ${this.persistLogin}`)
      this.$store.commit(StoreConstants.commits.setKeepMeLoggedIn, this.persistLogin)
    },
    googleOAuth() {
      console.log('sign in with google')
      console.log(this.$route.query.redirect)
      if(this.$route.query.redirect) {
        sessionStorage.setItem('oAuthRedirect', this.$route.query.redirect)
      } else {
        sessionStorage.removeItem('oAuthRedirect')
      }
      Auth.federatedSignIn({
        provider: 'Google'
      })
    },
    switchToForgotPassword() {
      this.$emit('page', AuthPages.forgotPassword)
    },
    async signIn() {
      // this.$emit('action', AuthActions.login)
      this.loading = true
      try {
        let error
        let result = await Auth.signIn(this.authData.email, this.authData.password)
          .catch(err => {
            error = err
          })
        console.log('sign in result')
        console.log(result)
        if(error && error.code === 'UserNotConfirmedException') {
          this.goToConfirmAccountPage()
          return
        }
        else if(error && error.code === 'PasswordResetRequiredException') {
          this.myAuthData.error = error
          this.authDataChange() //causes the snackbar to pop up saying password reset is required
          this.goToForgotPassword()
        } else if(error) {
          throw error
        }
        console.log('success!')

        await this.onAuthentication()

      } catch(e) {
        console.log('sign in error')
        console.log(e)
        this.myAuthData.error = e
        this.authDataChange()
      }
      this.loading = false
    },
    async submitNewPassword() {
      this.loading = true
      try {
        let error
        let cognitoUser = await Auth.completeNewPassword(this.authData.email.trim().toLowerCase(), this.authData.password, {
          email: this.authData.email.trim().toLowerCase()
        })
        .catch(err => {
          error = err
        })
        if(error) throw error
        console.log('success!')

        await this.onAuthentication()

      } catch(e) {
        console.log('sign in error')
        console.log(e)
        this.myAuthData.error = e
        this.authDataChange()
      }
      this.loading = false
    },
    authDataChange() {
      this.$emit('auth-data-change', this.myAuthData)
    },
    goToForgotPassword() {
      this.$emit('page', AuthPages.forgotPassword, true)
    },
    registerAccount() {
      this.$emit('page', AuthPages.signUp)
    },
    passwordValidChange(val) {
      console.log(`passwordValidChange fpc ${val}`)
      this.isPasswordValid = val
    },
    async onAuthentication() {
      let result = await Auth.currentAuthenticatedUser()
      console.log('onAuthentication')
      console.log(result)
      if(result.challengeName === 'NEW_PASSWORD_REQUIRED') {
        console.log('new password dialog')
        this.newPasswordDialog = true
      }
      else if(!result.signInUserSession.idToken.payload.email_verified) {
        console.log('!email_verified')
        await Auth.verifyCurrentUserAttribute('email')
        this.verifyEmailDialog = true
      } else {
        console.log('emitting')
        this.$emit('on-authentication')
      }
    }
  }
}
</script>

<style scoped>
.hr-sect {
  display: flex;
  flex-basis: 100%;
  align-items: center;
  margin: 8px 0px;
}
.hr-sect::before,
.hr-sect::after {
  content: "";
  flex-grow: 1;
  background: rgba(0, 0, 0, 0.6);
  height: 1px;
  font-size: 0px;
  line-height: 0px;
  margin: 0px 8px;
}

</style>
