<template>
  <div>
    <sign-in v-if="currentPageValue === pages.signIn"
             :auth-data="authData"
             @page="page"
             @auth-data-change="authDataChange"
             @on-authentication="onAuthenticated"
    >
    </sign-in>
    <sign-up v-else-if="currentPageValue === pages.signUp"
             :auth-data="authData"
             @page="page"
             @auth-data-change="authDataChange"
             @on-authentication="onAuthenticated"
    >
    </sign-up>
    <verify-account v-else-if="currentPageValue === pages.confirmAccount"
                    :auth-data="authData"
                    @page="page"
                    @auth-data-change="authDataChange"
                    @on-authentication="onAuthenticated"
    />
    <forgot-password v-else-if="currentPageValue === pages.forgotPassword"
                     :auth-data="authData"
                     @page="page"
                     @auth-data-change="authDataChange"
    >

    </forgot-password>
    <div v-else>
      {{`unknown page ${currentPage}`}}
    </div>
    <v-snackbar
        v-model="snackbar"
        :timeout="10000"
        :top="true"
        :absolute="true"
        :right="!$store.state.isMobile"
    >
      {{ snackbarMessage }}
      <template v-slot:action="{ attrs }">
        <v-btn icon v-bind="attrs" @click="snackbar = false">
          <v-icon>close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import AuthPages from "@/components/amplify/AuthPages"

import SignIn from "@/components/amplify/SignIn"
import SignUp from "@/components/amplify/SignUp"
import ForgotPassword from "@/components/amplify/ForgotPassword";
import VerifyAccount from "@/components/amplify/VerifyAccount";

export default {
  name: "AmplifyAuth",
  components: {
    VerifyAccount,
    SignIn,
    SignUp,
    ForgotPassword
  },
  data() {
    return {
      snackbar: false,
      snackbarMessage: '',
      pages: AuthPages,
      currentPageValue: this.initialPage,
      authData: {
        email: '',
        password: '',
        code: '',
        error: null,
        toastMessage: ''
      }
    }
  },
  props: {
    initialPage: {
      type: String,
      default: AuthPages.signUp
    }
  },
  methods: {
    onAuthenticated() {
      this.$emit('on-authentication')
    },
    authDataChange(val) {
      console.log(`authDataChange ${val}`)
      this.authData = val

      if(this.authData.toastMessage) {
        this.snackbar = true
        this.snackbarMessage = this.authData.toastMessage
        this.authData.toastMessage = null
      }
      else if(this.authData.error) {
        this.snackbar = true
        if(this.authData.error.message && this.authData.error.message !== 'An account with the given email already exists.'
        ) {
          this.snackbarMessage = this.authData.error.message
        } else {
          if(this.currentPageValue === AuthPages.signUp) {
            this.snackbarMessage = 'An error occured.  If you already have an account, switch to the sign in page instead of creating an account.'
          } else {
            this.snackbarMessage = 'An error occured.'
          }
        }
        this.authData.error = null
      }
    },
    page(nextPage) {
      console.log(`page ${nextPage}`)
      this.currentPageValue = nextPage
    }
  }
}
</script>

<style scoped>

</style>